import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import Settings from '@/inspectech-settings'
import en from '../locale/en'
import sv from '../locale/sv'

const darkTheme = localStorage.getItem(Settings.THEME_DARK) === 'true'

const vuetifyOpts = {
  theme: {
    dark: !!darkTheme,
    themes: {
      light: {
        primary: '#1b3ab5',
        secondary: '#f9b936',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
        background: '#fcfcfc',
        disabled: '#999999',
        help: '#f9b936',
        project: '#5E35B1',
        estate: '#303F9F',
        inspection: '#3949AB',
        object: '#5C6BC0',
        remark: '#FB8C00',
        file: '#2E7D32',
        photo: '#388E3C',
      },
      dark: {
        primary: '#1b3ab5',
        secondary: '#68BD12',
        accent: '#2196F3',
        error: '#FF5252',
        info: '#2196F3',
        success: '#1b5e20',
        warning: '#FF9100',
        background: '#1B2C06',
        disabled: '#666666',
        help: '#F9A825',
        project: '#4527a0',
        estate: '#283593',
        inspection: '#3F51B5',
        object: '#1976D2',
        remark: '#e65100',
        file: '#1B5E20',
        photo: '#2E7D32',
      }
    }
  },
  lang: {
    locales: { en, sv },
    current: navigator.language.substring(0, 2)
  },
  breakpoint: {
    mobileBreakpoint: 'sm' // This is equivalent to a value of 960
  },
  icons: {
    iconfont: 'mdi',
    values: {
      ok: 'mdi-check',
      close: 'mdi-close',
      add: 'mdi-plus',
      edit: 'mdi-pencil-outline',
      save: 'mdi-content-save',
      delete: 'mdi-delete',
      search: 'mdi-magnify',
      print: 'mdi-printer',
      reload: 'mdi-refresh',
      copy: 'mdi-content-copy',
      back: 'mdi-chevron-double-left',
      logout: 'mdi-logout-variant',
      info: 'mdi-information-outline',
      success: 'mdi-check',
      warning: 'mdi-alert-outline',
      error: 'mdi-alert-circle-outline',

      person: 'mdi-account',
      company: 'mdi-factory',
      group: 'mdi-account-multiple',
      inspection: 'mdi-home-search',
      entreprenad: 'mdi-home-city-outline',
      estate: 'mdi-home-group',
      object: 'mdi-home',
      remark: 'mdi-eye',
      order: 'mdi-cart-outline',
      quote: 'mdi-file-document-outline',
      question: 'mdi-chat-question-outline',
      eye: 'mdi-eye',
      list: 'mdi-format-list-bulleted',
      help: 'mdi-help-circle',
      check: 'mdi-check-circle-outline',
      lock: 'mdi-lock-outline',
      unlock: 'mdi-lock-open-variant-outline',
      magnify: 'mdi-magnify',
      bubble: 'mdi-chart-bubble',
      alert: 'mdi-alert',
      cloud: 'mdi-cloud',
      cog: 'mdi-cog',
      car: 'mdi-car',
      file: 'mdi-file-cabinet',
      content: 'mdi-file-cabinet',
      home: 'mdi-home',
      bank: 'mdi-bank',
      city: 'mdi-city',
      map: 'mdi-map-marker',
      bell: 'mdi-bell',
      camera: 'mdi-camera',
      audio: 'mdi-volume-medium',
      calendar: 'mdi-calendar',
      key: 'mdi-key',
      favorite: 'mdi-star',
      heart: 'mdi-heart',
      mushroom: 'mdi-mushroom',
      tree: 'mdi-pine-tree',
      water: 'mdi-water',
      lightbulb: 'mdi-lightbulb',
      radioactive: 'mdi-radioactive',
      ruler: 'mdi-ruler-square',
      sequence: 'mdi-numeric',
      upload: 'mdi-cloud-upload-outline',
      download: 'mdi-cloud-download-outline',
      share: 'mdi-share',
      'share-off': 'mdi-share-off',
      security: 'mdi-security',
      money: 'mdi-currency-eur',
      phone: 'mdi-phone',
      address: 'mdi-email',
      mail: 'mdi-email',
      email: 'mdi-at',
      internet: 'mdi-web',
      www: 'mdi-web',
      social: 'mdi-web',
      twitter: 'mdi-twitter',
      linkedin: 'mdi-linkedin',
      instagram: 'mdi-instagram',
      facebook: 'mdi-facebook'
    }
  }
}

Vue.use(Vuetify)

export default new Vuetify(vuetifyOpts)
