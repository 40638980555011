<template>
    <div v-once :id="id" class="google-map"></div>
</template>

<script>
import googleMapsLoader from '../lib/google-maps-loader'

var theMap
var directionsService
var directionsRenderer
var internalMarkers = []

export default {
  name: 'GoogleMaps',
  props: {
    id: {
      type: String,
      default: () => 'mymap'
    },
    home: {
      type: String,
      default: "Odelbergs väg 11, 134 40 Gustavsberg"
    },
    markers: {
      type: Array,
      default: () => [{ position: { lat: 59.3245, lng: 18.3818 } }]
    },
    address: {
      type: String
    },
    zoom: {
      type: Number,
      default: function () {
        return 12
      }
    }
  },
  data: () => ({
    homeLocation: null
  }),
  methods: {
    refresh() {
      googleMapsLoader(process.env.VUE_APP_GOOGLE_MAPS_API_KEY)
        .then(api => {
          // Remove old map content
          this.clearDirections(api)
          this.clearMarkers(api)

          if(this.address) {
            this.getLocationForAddress(api, this.home)
              .then(homeLocation => (this.homeLocation = homeLocation))
              .then(() => this.getLocationForAddress(api, this.address, 'location'))
              .then(targetLocation => ([targetLocation, ...this.mapCustomLocations(api, this.markers)]))
              .then(locations => this.renderMarkers(api, locations))
              .then(locations => this.getDirections(api, this.homeLocation, locations[0]))
              .then(directions => this.renderDirections(api, directions))
              .then(directions => this.$emit('directions', directions.routes[0].legs[0]))
          } else if(this.markers && this.markers.length > 0) {
            this.renderMarkers(api, this.mapCustomLocations(api, this.markers))
          } else {
            // Nothing to draw
          }
        })
    },
    createMap(api, position) {
      if (theMap === undefined) {
        theMap = new api.Map(this.$el, { center: position, zoom: this.zoom })
      } else {
        theMap.setCenter(position)
      }
    },
    clearDirections(api) {
      this.renderDirections(api, { routes: [] })
    },
    clearMarkers(api) {
      for (let i = 0; i < internalMarkers.length; i++) {
        internalMarkers[i].setMap(null)
      }
      internalMarkers = []
    },
    getLocationForAddress(api, address, event) {
      const vm = this
      return new Promise(function (resolve, reject) {
        const geocoder = new api.Geocoder()
        geocoder.geocode({
          componentRestrictions: { country: 'SE' },
          address: address
        }, function (results, status) {
          if (status === 'OK') {
            const location = results[0].geometry.location
            if (event) {
              vm.$emit(event, { latitude: location.lat(), longitude: location.lng() })
            }
            resolve(location)
          } else {
            reject(new Error('Geocode was not successful for the following reason: ' + status))
          }
        })
      })
    },
    mapCustomLocations(api, locations) {
      return locations ? locations.map(loc => ({ position: { lat: loc.latitude, lng: loc.longitude }, title: loc.title })) : []
    },
    renderMarkers (api, locations) {
      for (let i = 0; i < locations.length; i++) {
        let entry = locations[i]
        if(i === 0) {
          this.createMap(api, entry.position)
        }
        internalMarkers.push(new api.Marker({ map: theMap, position: entry.position, title: entry.title }))
      }
      return locations
    },
    getDirections (api, fromLocation, toLocation) {
      if(directionsService === undefined) {
        directionsService = new api.DirectionsService()
      }
      const route = {
        origin: fromLocation,
        destination: toLocation,
        travelMode: 'DRIVING'
      }
      return new Promise(function (resolve, reject) {
        directionsService.route(route,
          function (response, status) { // anonymous function to capture directions
            if (status === 'OK') {
              var directionsData = response.routes[0].legs[0] // Get data about the mapped route
              if (directionsData) {
                resolve(response)
              } else {
                reject(new Error('Directions request failed for unknown reason'))
              }
            } else {
              reject(new Error('Directions request failed due to ' + status))
            }
          })
      })
    },
    renderDirections(api, directions) {
      this.createMap(api, this.homeLocation)
      if(directionsRenderer === undefined) {
        directionsRenderer = new api.DirectionsRenderer()
        directionsRenderer.setMap(theMap)
      }
      directionsRenderer.setDirections(directions) // Add route to the map
      return Promise.resolve(directions)
    }
  }
}
</script>

<style scoped>
    .google-map {
        width: 100%;
        height: 480px;
    }
</style>
