export default [
  {
    path: '/:tenant/contact/email/:id',
    name: 'contact-email',
    props: true,
    component: () => import(/* webpackChunkName: "inspection" */ '../views/contact/ComposeContactEmail.vue'),
    meta: {
      tenant: true
    }
  },
]
