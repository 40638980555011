const mutations = {
  setLoading (state, arg) {
    if (arg) {
      state.loading++
    } else if (state.loading > 0) {
      state.loading--
    }
  },
  setBreadcrumbs (state, array) {
    state.breadcrumbs = array
  },
  addHistory (state, arg) {
    const fifo = state.history.filter(entry => JSON.stringify(entry.to) !== JSON.stringify(arg.to))
    if (fifo.length > 9) {
      fifo.pop()
    }
    fifo.unshift(arg)
    state.history = fifo
  },
  clearHistory (state) {
    state.history = []
  },
  setClipboardObject(state, value) {
    state.clipboard = value
  },
  setRightDrawer (state, visible) {
    state.rightDrawer = visible
  },
  resetState (state) {
    //state.loading = 0
    state.features = []
    state.itemsPerPage = 10
    state.itemsPerPageOptions = [5, 10, 25, 50, 100, -1]
    state.rightDrawer = false
    state.breadcrumbs = []
    state.history = []
  },
  setFeatures (state, list) {
    state.features = [...list]
  },
  setAssetsTenant(state, tenant) {
    state.assets = tenant
  },
  setStaticPath(state, path) {
    state.staticPath = path
  },
  RESET_ORDER (state) {
    state.order = {
      source: '',
      orderType: 'order',
      types: [],
      preferredDate: '',
      customer: {
        address: {}
      },
      owner1: {
        address: {}
      },
      owner2: {
        address: {}
      },
      broker: {
        address: {}
      },
      object: {}
    }
  },
  setLastSavedRemark (state, item) {
    state.lastSavedRemark = item ? Object.assign({}, item) : null
  },
  setHomeView (state, view) {
    state.homeView = view
  },
  cacheInspectionMetadata(state, data) {
    state.inspectionMetadata = data
  },
  saveInspectionSearch(state, formData) {
    state.inspectionSearch = { ...formData }
  },
  saveOrderSearch(state, formData) {
    state.orderSearch = { ...formData }
  },
  setCalendarType(state, type) {
    state.calendarType = type
  },
  reload (state, data) {
    // Dummy mutation. An event handler in the related view will act on this mutation type.
  }
}

export default mutations
