const constants = {
  METADATA_GROUP_CONTACT: 'contact',
  METADATA_GROUP_ORDER: 'order',
  METADATA_GROUP_INSPECTION: 'inspection',
  METADATA_CONTACT_RELATION_TYPE: 'contact.relation.type',
  METADATA_ORDER_TYPE: 'order.type',
  METADATA_CONTACT_ROLE: 'contact.role',
  METADATA_OBJECT_ATTRIBUTE: 'object.attribute',
  METADATA_OBJECT_STATUS: 'object.status',
  METADATA_INSPECTION_STATUS: 'inspection.status',

  CONTACT_ROLE_CUSTOMER: 'customer',

  service: {
    VB_SERVICE: 'vb',
    INSPECTION_SERVICE: 'inspections',
    ACTIVITY_SERVICE: 'activity',
    CONTACT_SERVICE: 'contact',
    CONTENT_SERVICE: 'content',
    SPEECH_SERVICE: 'speech',
    SEQUENCE_SERVICE: 'sequences',
    EMAIL_SERVICE: 'email',
    BILLING_SERVICE: 'billing'
  }
}

export default constants
