export default {
  install (Vue, { store }) {
    if (!store) {
      throw new Error('Please provide vuex store.')
    }
    const CRM = Vue.prototype.$crm || {}
    Vue.prototype.$crm = CRM

    Object.defineProperty(CRM, 'tenant', { get: function() { return store.getters['account/tenantId'] } })

    CRM.getTenantUsers = function () {
      return store.dispatch('account/getTenantUsers')
    }

    CRM.getUser = function () {
      const user = store.getters['account/user']
      if (user) {
        return store.dispatch('account/getTenantUsers')
          .then(users => users.filter(u => u.sub === user.sub)[0])
      }
      return Promise.resolve(null)
    }
  }
}
