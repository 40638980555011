export const mutations = {
  resetState (state) {
    state.tenant = null
    state.accounts = []
    //state.user = null
    state.account = null
    state.roles = []
    state.avatar = null
    state.logo = null
    state.permissions = []
  },
  setCurrentTenant (state, tenant) {
    state.tenant = tenant
    localStorage.setItem('tenant', tenant?.id)
  },
  setCurrentAccount (state, account) {
    if(account) {
      const id = account.id
      if(state.accounts.find(a => a.id === id)) {
        state.account = account
      } else {
        console.error('tried to set invalid account as current', account)
      }
    } else {
      state.account = null
    }
  },
  setCurrentRoles (state, roles) {
    state.roles = [...roles]
  },
  setUser (state, user) {
    state.user = user
    //localStorage.setItem('user_info', JSON.stringify(user))
  },
  setAccounts (state, accounts) {
    const items = [...accounts]
    items.sort(function(a, b) {
      var nameA = a.tenant.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.tenant.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    state.accounts = items
  },
  setAvatar (state, dataUrl) {
    state.avatar = dataUrl
  },
  setLogo (state, dataUrl) {
    state.logo = dataUrl
  },
  setUserSettings (state, obj) {
    state.userSettings = {...obj}
  },
  setPermissions (state, list) {
    state.permissions = list.map(p => new RegExp(p))
  }
}
