<template>
  <v-app :dark="darkTheme">

    <v-snackbar v-if="message" :multi-line="!!message.body" :timeout="snackbarTimeout" top v-model="snackbarVisible" :color="getMessageColor(message)">
      <div class="clickable" @click="snackbarClicked">
        <div><b>{{ message.subject }}</b></div>
        <div>{{ message.body }}</div>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn text outlined color="white" v-bind="attrs" @click.stop="snackbarVisible = false">Stäng</v-btn>
      </template>
    </v-snackbar>

    <v-app-bar app v-if="isTenantActive">

      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <CrmAvatar
              :value="org"
              property="icon"
              :size="36"
            ></CrmAvatar>
          </v-btn>
        </template>

        <v-list role="navigation">

          <!--
          <v-subheader aria-label="Moduler">Moduler</v-subheader>
          -->

          <v-list-item
            v-for="item in menuItems"
            :key="item.path"
            @click="goto(item)"
          >
            <v-list-item-action>
              <v-icon v-text="item.meta.icon"></v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{$vuetify.lang.t('$vuetify.' + item.meta.title)}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="showPublicCalendar" :href="calendarPageUrl" target="_blank">
            <v-list-item-icon>
              <v-icon>mdi-calendar-search</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Bokningskalendern</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :href="orderPageUrl" target="_blank">
            <v-list-item-icon>
              <v-icon>mdi-earth</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Beställningssidan</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-menu>

      <v-toolbar-title v-text="title" class="ml-3 mb-1"></v-toolbar-title>

      <v-breadcrumbs v-if="$vuetify.breakpoint.smAndUp" divider="→">
        <v-breadcrumbs-item
          v-for="item in breadcrumbs"
          :to="item.to"
          :exact="true"
          :key="item.text"
          :disabled="item.disabled"
        >
          <span :class="$vuetify.theme.dark ? 'grey--text' : null">{{ item.text }}</span>
        </v-breadcrumbs-item>
      </v-breadcrumbs>

      <v-spacer></v-spacer>

      <!--
      <v-text-field
        hide-details
        prepend-icon="search"
        single-line
      ></v-text-field>
      -->

      <span class="mr-3 clickable hidden-xs-only" @click="$router.push({ name: 'settings', params: { tenant: $crm.tenant } })">{{account.name}}</span>

      <v-badge class="mr-2" dot color="green" offset-x="3" offset-y="6" :value="isSocketConnected">
        <CrmAvatar
          :value="account"
          :size="36"
          class="clickable"
          @click="$router.push({ name: 'settings', params: { tenant: $crm.tenant } })"
        ></CrmAvatar>
      </v-badge>

      <v-badge v-if="notifications.length" color="red" offset-x="16" offset-y="18" :value="unreadNotifications > 0">
        <template v-slot:badge>
          <span>{{ unreadNotifications }}</span>
        </template>
        <v-btn icon @click="notificationsVisible = !notificationsVisible"><v-icon>mdi-email</v-icon></v-btn>
      </v-badge>

      <v-btn v-if="$crm.tenant && isAdmin"
             icon
             exact
             :to="{ name: 'admin', params: { tenant: $crm.tenant } }"
      >
        <v-icon>mdi-cogs</v-icon>
      </v-btn>

      <v-menu v-if="availableAccounts.length > 1">
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            class="mr-2"
          >
            <v-icon>mdi-bank</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="item in availableAccounts"
            :key="item.id"
            @click="selectAccount(item)"
            :disabled="item.id === currentAccountId"
          >
            <v-list-item-icon>
              <img
                v-if="item.tenant.icon"
                :src="`/${item.tenant.id}/v1/content/assets/${item.tenant.icon}`"
                height="36"
                width="36"
                alt="Logo"
              >
              <v-icon v-else>$company</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.tenant.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item :to="{ name: 'tenant' }">
            <v-list-item-icon>
              <v-icon>$company</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Mina organisationer</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-menu>

      <v-btn icon @click.stop="$store.dispatch('toggleRightDrawer')">
        <v-icon>menu</v-icon>
      </v-btn>
    </v-app-bar>

    <v-app-bar app v-else>
      <v-avatar size="36px" color="primary">
        <v-app-bar-nav-icon class="white--text"></v-app-bar-nav-icon>
      </v-avatar>

      <v-spacer></v-spacer>

      <span class="mr-3 clickable hidden-xs-only">
        {{$store.getters['account/user'].name}}
      </span>

      <v-btn icon @click="logout" title="Logga ut">
        <v-icon>$logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main style="padding: 64px 0px 80px 0px;">

      <div style="height:5px;">
        <v-progress-linear v-show="$store.getters.isLoading" height="5" :indeterminate="true"></v-progress-linear>
      </div>

      <router-view/>

    </v-main>

    <v-navigation-drawer
      temporary
      :right="true"
      :width="480"
      :value="rightDrawer"
      fixed
      app
      @input="rightDrawerStatus($event)"
    >
      <router-view name="sidebar"></router-view>

      <v-list>
        <v-subheader>{{$vuetify.lang.t('$vuetify.app.menu.history')}}</v-subheader>
        <v-list-item
          v-for="(item, i) in history"
          :key="i"
          :style="'opacity:' + (10 - i) / 10"
          @click="showHistory(item)"
        >
          <v-list-item-action>
            <v-icon v-html="item.icon"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{item.text}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>

    <CrmNotificationList
      :visible="notificationsVisible"
      :items="notifications"
      @read="toggleRead"
      @remove="removeNotification"
      @reset="removeAllNotifications"
      @close="closeNotifications"
    />

    <AppFooter></AppFooter>

    <v-btn
      v-if="$crm.tenant"
      :href="`/help/${$crm.tenant}`"
      target="_blank"
      color="warning"
      dark
      small
      bottom
      right
      fixed
      fab
    >
      <span class="headline">?</span>
    </v-btn>

    <MapDialog
      :visible="mapVisible"
      :home="homeAddress"
      :title="mapTitle"
      :address="mapAddress"
      :position="mapPosition"
      @close="showMap({})"
    ></MapDialog>

    <audio id="audio" ref="audio" :src="audioSource" preload="none"></audio>

  </v-app>
</template>

<script>
/* eslint-disable no-console, no-unused-vars */
import moment from 'moment'
import startSound from '@/assets/media/blip1.mp3'
import stopSound from '@/assets/media/blip2.mp3'
import CrmNotificationList from './components/CrmNotificationList'
import AppFooter from '@/components/AppFooter'
import MapDialog from '@/components/MapDialog'
import CrmAvatar from './components/base/CrmAvatar'

export default {
  name: 'App',
    components: { CrmAvatar, MapDialog, AppFooter, CrmNotificationList },
  data () {
    return {
      settings: {
        'order.calendar.enabled': null
      },
      snackbarVisible: false,
      snackbarTimeout: 10000,
      notificationsVisible: false,
      mapVisible: false,
      mapTitle: null,
      mapAddress: null,
      mapPosition: null,
      audio: undefined,
      audioSource: undefined,
      playing: false,
      paused: false,
    }
  },
  computed: {
    availableAccounts () {
      return this.$store.getters['account/accounts']
    },
    account () {
      return this.$store.getters['account/account']
    },
    currentAccountId () {
      return this.account?.id
    },
    title () {
      if(this.isTenantActive) {
        return this.$store.getters['account/tenantName']
      }
      return ''
    },
    isAdmin () {
      return this.$store.getters['account/isAdmin']
    },
    isAuthenticated () {
      return this.$store.getters['account/isAuthenticated']
    },
    isTenantActive () {
      return !!this.$store.getters['account/tenantId']
    },
    breadcrumbs () {
      return this.$store.getters.breadcrumbs
    },
    history () {
      return this.$store.getters.history
    },
    rightDrawer () {
      return this.$store.state.rightDrawer
    },
    menuItems () {
      if (!this.isTenantActive) {
        return []
      }
      return this.$router.options.routes.filter(route => {
        if (!route.meta) {
          return false
        }
        if (!route.meta.menu) {
          return false
        }
        if (route.meta.feature && !this.$store.getters.feature(route.meta.feature)) {
          return false
        }
        if (route.meta.permission && !this.$store.getters['account/permission'](route.meta.permission)) {
          return false
        }
        return true
      })
    },
    orderPageUrl () {
      return process.env.VUE_APP_ORDER + '/' + this.$crm.tenant
    },
    calendarPageUrl () {
      return this.orderPageUrl + '/calendar'
    },
    showPublicCalendar () {
      return !!this.settings['order.calendar.enabled']
    },
    message () {
      return this.$store.getters.message
    },
    homeAddress () {
      return this.$store.getters['account/userSetting']('homeAddress') || this.$store.getters['account/tenantAddress']
    },
    darkTheme () {
      return !!this.$store.getters['account/userSetting']('darkTheme')
      //return localStorage.getItem('theme.dark') === 'true'
    },
    avatar () {
      const tenant = this.$crm.tenant
      const account = this.account
      if(tenant && account?.picture) {
        return `/${tenant}/v1/content/assets/${account.picture}?variant=thumbnail`
      }
      return null
    },
    org () {
      return this.$store.getters['account/tenant']
    },
    logo () {
      const tenant = this.org
      if(tenant?.icon) {
        return `/${tenant.id}/v1/content/assets/${tenant.icon}?variant=thumbnail`
      }
      return null
    },
    notifications () {
      return this.$store.getters.notifications || []
    },
    unreadNotifications () {
      return this.notifications.filter(n => n.read === false).length
    },
    isSocketConnected () {
      return this.$store.getters.isSocketConnected
    },
    isSocketError () {
      return this.$store.getters.isSocketError
    }
  },
  methods: {
    selectAccount (item) {
      this.$router.replace('/' + item.tenant.id)
    },
    fetchSettings () {
      const promises = []
      Object.keys(this.settings).forEach(key =>
        promises.push(this.$store.dispatch('settings/getTenantSetting', key)
          .then(value => this.$set(this.settings, key, value))
        ))
      return Promise.all(promises)
    },
    goto (item) {
      this.$router.push({ name: item.name, params: { tenant: this.$crm.tenant } })
        .catch(() => true)
    },
    rightDrawerStatus (event) {
      this.$store.commit('setRightDrawer', event)
    },
    showHistory(item) {
      this.rightDrawerStatus(false)
      this.$nextTick(() => this.$router.push(item.to).catch(() => true))
    },
    showMap({ address, position }) {
      if (address) {
        this.mapTitle = null
        this.mapPosition = null
        this.mapAddress = address
        this.mapVisible = true
      } else if (position) {
        this.mapTitle = position.title
        this.mapAddress = null
        this.mapPosition = position
        this.mapVisible = true
      } else {
        this.mapTitle = null
        this.mapAddress = null
        this.mapPosition = null
        this.mapVisible = false
      }
    },
    toggleRead (item) {
      if (!item.read) {
        this.$store.dispatch('toggleRead', item)
      }
      this.handleAttachments(item)
    },
    closeNotifications () {
      this.notificationsVisible = false
    },
    removeNotification (item) {
      this.$store.dispatch('deleteNotification', item)
    },
    removeAllNotifications () {
      this.$store.dispatch('deleteAllNotifications')
    },
    snackbarClicked () {
      this.handleAttachments(this.message)
    },
    parseTimeout (timeout) {
      if (timeout) {
        if (timeout === 'short') {
          return 2000
        } else if (timeout === 'normal' || timeout === 'medium') {
          return 5000
        } else if (timeout === 'long') {
          return 10000
        } else {
          return Math.max(timeout, 1000)
        }
      }
      return 5000
    },
    handleAttachments (notification) {
      if (notification.attachments && notification.attachments.length) {
        this.openContent(notification.attachments[0])
      } else if(notification.attributes?.route) {
        this.$router.push(notification.attributes?.route)
      }
    },
    openContent (path) {
      return this.$store.dispatch('content/getContentMetadata', path)
        .then(content => this.$store.dispatch('content/downloadContent', content.id)
          .then(blob => {
            const data = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = data
            link.target = '_blank'
            link.download = content.filename // Trick to make filename appear in browser window
            link.click()
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data)
            }, 250)
          })
        )
    },
    handleError ({ message, error }) {
      const text = message?.startsWith('$vuetify.') ? this.$vuetify.lang.t(message) : message
      this.$store.dispatch('message', { subject: text, body: error, color: 'error darken-1', timeout: 10000 })
    },
    getMessageColor (message) {
      if (message.color) {
        return message.color
      }
      if (message.category === 'REMINDER') {
        return 'info darken-1'
      }
      if (message.category === 'ERROR') {
        return 'error darken-1'
      }
      return 'success darken-2'
    },
    onActivityCreated({ activity }) {
      this.$router.push({ name: 'dashboard', params: { tenant: this.$crm.tenant, view: 'calendar', date: moment(activity.startTime).format('YYYY-MM-DD') }})
    },
    initAudio () {
      this.audio = this.$refs.audio;

      if (this.audio !== undefined) {
        this.audio.addEventListener(
          'ended',
          this.onAudioEnded,
          false,
        )
      }

      this.$eventBus.$on('audio:play', this.playAudio)
      this.$eventBus.$on('audio:stop', this.stopAudio)
    },
    onAudioEnded() {
      this.playing = false
      this.paused = false
    },
    destroyAudio() {
      this.$eventBus.$off('audio:stop', this.stopAudio)
      this.$eventBus.$off('audio:play', this.playAudio)

      if (this.audio !== undefined) {
        this.audio.removeEventListener('ended', this.onAudioEnded, false)
      }
    },
    playAudio (item) {
      const name = item.name || item.filename
      const data = item.audio || item.data
      if (name === 'start-recording') {
        this.doPlay(startSound)
      } else if (name === 'stop-recording') {
        this.doPlay(stopSound)
      } else if (data) {
        this.doPlay(data)
      }
    },
    doPlay (audio) {
      this.audioSource = audio
      this.audio.load()
      setTimeout(function() {
        this.audio.play()
          .then(() => (this.playing = true))
          .catch(console.error)
      }, 50)
    },
    stopAudio ({ name }) {
      this.audio.pause()
      this.audioSource = undefined
    },
    logout () {
      this.$eventBus.$emit('logout')
    }
  },
  created () {
    this.$eventBus.$on('error', this.handleError)
    this.$eventBus.$on('activity:created', this.onActivityCreated)
    this.$eventBus.$on('map:show', this.showMap)
    this.$eventBus.$on('content:open', this.openContent)
    this.$eventBus.$on('application:init', this.fetchSettings)
  },
  mounted () {
    this.initAudio()
  },
  beforeDestroy () {
    this.destroyAudio()
    this.$eventBus.$off('application:init', this.fetchSettings)
    this.$eventBus.$off('content:open', this.openContent)
    this.$eventBus.$off('map:show', this.showMap)
    this.$eventBus.$off('activity:created', this.onActivityCreated)
    this.$eventBus.$off('error', this.handleError)
  },
  watch: {
    message: function (oldMsg, newMsg) {
      const timeout = this.parseTimeout(newMsg?.timeout)
      // Snackbar timer is reset only when timout is changed,
      // therefore we add a random number between 0-100 to force it to change every time.
      // Without this (workaround) a new message that arrives 2 seconds before the previous message would timeout will only be visible for 2 seconds.
      this.snackbarTimeout = timeout + Math.floor(Math.random() * 100)
      this.snackbarVisible = false;
      this.$nextTick(() => (this.snackbarVisible = true))
      this.$store.dispatch('fetchNotifications') // refresh notification list
        .catch(console.error)
    }
  }
}
</script>

<style>
  .v-date-picker-table {
    height: 100%;
    max-height: 300px;
  }
  .theme--light.v-card>.v-card__text {
    color: rgba(0,0,0,.8) !important;
  }
  .clickable {
    cursor: pointer;
  }
  .archived td {
    text-decoration: line-through;
  }
  .system td {
    color: darkred;
  }
</style>

<style scoped>
  .v-progress-linear {
    margin: 0;
  }
</style>
