const getters = {
  apiUrl: (state) => process.env.VUE_APP_API,
  isLoading (state) {
    return state.loading > 0
  },
  breadcrumbs (state) {
    return state.breadcrumbs
  },
  history (state) {
    return state.history
  },
  itemsPerPage (state) {
    return state.itemsPerPage
  },
  itemsPerPageOptions (state) {
    return state.itemsPerPageOptions
  },
  feature: (state) => (key) => {
    return state.features.find(f => f.id === key)
  },
  assets (state) {
    return state.assets
  },
  staticContent (state, getters, rootState, rootGetters) {
    const tenant = rootGetters['account/tenantId'] || '0'
    return `${process.env.VUE_APP_API}/${tenant}/v1/vb/public`
  },
  lastSavedRemark (state) {
    return state.lastSavedRemark || {}
  },
  startView (state) {
    return state.userSettings.startView
  },
  isFavorite: (state) => (target) => {
    return state.favorites[target]
  },
  calendarType (state) {
    return state.calendarType || 'month'
  },
  appointedBy: (state) => (name) => {
    return state.appointedByOptions.find(opt => opt.name === name)
  },
  appointedByOptions(state) {
    return state.appointedByOptions
  },
  genderOptions (state) {
    return []
  },
  clipboard (state) {
    return state.clipboard
  }
}

export default getters
