export const getters = {
  tenant (state) {
    return state.tenant
  },
  tenantId (state) {
    return state.tenant?.id
  },
  tenantName (state) {
    return state.tenant?.name || ''
  },
  tenantAddress (state) {
    return state.tenant?.address?.value
  },
  tenants (state) {
    const list = state.accounts.map(a => (a.tenant))
    list.sort(function(a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
    return list
  },
  user (state) {
    return state.user || {}
  },
  account (state) {
    return state.account || {}
  },
  accountId (state) {
    return state.account?.id
  },
  accountName (state) {
    return state.account?.name
  },
  accounts (state) {
    return state.accounts || []
  },
  isOwner (state) {
    return state.roles.includes('owner')
  },
  isAdmin (state) {
    return state.roles.includes('owner') || state.roles.includes('admin')
  },
  isAuthenticated (state) {
    return !!state.user
  },
  role: (state) => (key) => {
    return state.roles.includes(key)
  },
  userSetting: (state) => (key) => {
    return state.userSettings[key]
  },
  permission: (state) => (key) => {
    //return state.permissions.includes(key)
    return !!state.permissions.find(p => p.test(key))
  },
}
