import C from '@/store/constants'

const VB_SERVICE_NAME = C.service.VB_SERVICE

export default {
  namespaced: true,

  actions: {

    status ({ dispatch }) {
      return new Promise(function (resolve, reject) {
        dispatch('API', VB_SERVICE_NAME, { root: true })
          .then(api => api.get('/visma'))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },

    activate ({ dispatch }, args) {
      return new Promise(function (resolve, reject) {
        dispatch('API', VB_SERVICE_NAME, { root: true })
          .then(api => api.post('/visma', args))
          .then(response => {
            console.log(response)
            resolve(response.data)
          })
          .catch(err => reject(err))
      })
    },

    uninstall ({ dispatch }) {
      return new Promise(function (resolve, reject) {
        dispatch('API', VB_SERVICE_NAME, { root: true })
          .then(api => api.delete('/visma'))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },

    activation ({ dispatch }, args) {
      return new Promise(function (resolve, reject) {
        dispatch('API', VB_SERVICE_NAME, { root: true })
          .then(api => api.post('/visma/activation', args))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },

    getCompanyInformation ({ dispatch }) {
      return new Promise(function (resolve, reject) {
        dispatch('API', VB_SERVICE_NAME, { root: true })
          .then(api => api.get('/visma/companyinformation'))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },

    getAllCustomers ({ dispatch }) {
      return new Promise(function (resolve, reject) {
        dispatch('API', VB_SERVICE_NAME, { root: true })
          .then(api => api.get('/visma/customers'))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },

    getCustomersByName ({ dispatch }, name) {
      return new Promise(function (resolve, reject) {
        dispatch('API', VB_SERVICE_NAME, { root: true })
          .then(api => api.get('/visma/customers?name=' + encodeURIComponent(name)))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },

    getCustomersByEmail ({ dispatch }, { name, email }) {
      return new Promise(function (resolve, reject) {
        dispatch('API', VB_SERVICE_NAME, { root: true })
          .then(api => api.get('/visma/customers?name=' + encodeURIComponent(name) + "&email=" + encodeURIComponent(email)))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },

    getCustomersByPostalCode ({ dispatch }, { name, postalCode }) {
      return new Promise(function (resolve, reject) {
        dispatch('API', VB_SERVICE_NAME, { root: true })
          .then(api => api.get('/visma/customers?name=' + encodeURIComponent(name) + "&postalCode=" + encodeURIComponent(postalCode)))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },

    getCustomer ({ dispatch }, number) {
      return new Promise(function (resolve, reject) {
        dispatch('API', VB_SERVICE_NAME, { root: true })
          .then(api => api.get('/visma/customers/' + encodeURIComponent(number)))
          .then(response => resolve(response.data))
          .catch(err => resolve(null))
      })
    },

    getAllInvoices ({ dispatch }) {
      return new Promise(function (resolve, reject) {
        dispatch('API', VB_SERVICE_NAME, { root: true })
          .then(api => api.get('/visma/invoices'))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },

    createInspectionInvoices({ dispatch }, data) {
      return new Promise(function (resolve, reject) {
        return dispatch('API', VB_SERVICE_NAME, { root: true })
          .then(api => api.post('/visma/invoices/', data))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },
  }
}
