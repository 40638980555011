import { CrmContactListView } from 'vue-gr8crm-contact'

export default [
  {
    path: '/:tenant/order/:id',
    name: 'order',
    component: () => import(/* webpackChunkName: "order" */ '../views/order/OrderView'),
    meta: {
      title: 'order.view.title',
      icon: 'mdi-cart-outline',
      tenant: true,
    }
  },
  {
    path: '/:tenant/inspections/create',
    name: 'add-inspection',
    component: () => import(/* webpackChunkName: "inspection" */ '../views/CreateInspectionView'),
    meta: {
      title: 'inspection.create.label',
      icon: 'mdi-file-plus-outline',
      tenant: true,
      menu: true
    }
  },
  {
    path: '/:tenant/inspections/:id/email',
    name: 'inspection-email',
    props: true,
    component: () => import(/* webpackChunkName: "inspection" */ '../views/SendEmailView'),
    meta: {
      tenant: true
    }
  },
  {
    path: '/:tenant/inspections',
    name: 'inspections',
    component: () => import(/* webpackChunkName: "inspection" */ '../components/inspection/InspectionIndex'),
    meta: {
      title: 'inspection.search.title',
      icon: 'mdi-file-question-outline',
      tenant: true,
      menu: true
    }
  },
  {
    path: '/:tenant/contact',
    name: 'crm-contact-list',
    component: CrmContactListView,
    meta: {
      title: 'crm.contact.list.label',
      icon: 'mdi-contacts-outline',
      tenant: true,
      menu: true
    }
  },
  {
    path: '/:tenant/order',
    name: 'find-order',
    component: () => import(/* webpackChunkName: "order" */ '../views/order/FindOrderView'),
    meta: {
      title: 'order.search.title',
      icon: 'mdi-cart-outline',
      tenant: true,
      menu: true
    }
  },
]
