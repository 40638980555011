<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile" eager :value="visible" max-width="720px" persistent @keydown.esc="close">
    <v-card>

      <v-card-title v-text="dialogTitle"></v-card-title>

      <v-card-text>
        <GoogleMaps
            id="inspection-map"
            ref="map"
            :home="home"
            :address="address"
            :markers="markers"
            :zoom="zoom"
            @location="location = $event"
            @directions="showDirections"
        />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-row dense align="center">
          <v-col cols="12" md="6" class="text-center">
            <span v-if="directions.distance || directions.duration">{{directions.distance && directions.distance.text}} {{directions.duration && directions.duration.text}}</span>
          </v-col>
          <v-col cols="12" md="4" class="text-center">
            <a v-if="googleLink" :href="googleLink" target="_blank" :class="{ 'pr-3': true, 'text-decoration-none': true, 'caption': !$vuetify.breakpoint.mobile }"><v-icon x-small>$map</v-icon> Google</a>
            <a v-if="appleLink" :href="appleLink" target="_blank" :class="{ 'pr-3': true, 'text-decoration-none': true, 'caption': !$vuetify.breakpoint.mobile }"><v-icon x-small>$map</v-icon> Apple</a>
            <a v-if="wazeLink" :href="wazeLink" target="_blank" :class="{ 'pr-3': true, 'text-decoration-none': true, 'caption': !$vuetify.breakpoint.mobile }"><v-icon x-small>$map</v-icon> Waze</a>
          </v-col>
          <v-col cols="12" md="2">
            <crm-close-button :block="$vuetify.breakpoint.mobile" @click="close" class="mt-3 mt-md-1"></crm-close-button>
          </v-col>
        </v-row>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import GoogleMaps from './GoogleMaps'

export default {
  name: 'MapDialog',
  components: { GoogleMaps },
  props: {
    visible: {
      type: Boolean
    },
    home: {
      type: String
    },
    address: {
      type: String
    },
    position: {
      type: Object,
    },
    title: {
      type: String
    }
  },
  data: () => ({
    location: null,
    directions: {}
  }),
  computed: {
    dialogTitle () {
      if (this.title) {
        return this.title
      } else if(this.address) {
        return this.address
      } else if(this.position?.title) {
        return this.position.title
      } else if(this.position) {
        const latitude = new Intl.NumberFormat().format(this.position.latitude || 0)
        const longitude = new Intl.NumberFormat().format(this.position.longitude || 0)
        return `${latitude},${longitude}`
      } else {
        return null
      }
    },
    zoom () {
      return this.address ? 12 : 15
    },
    markers () {
      return this.position ? [this.position] : []
    },
    googleLink () {
      return this.location ? `https://www.google.com/maps/search/?api=1&query=${this.location?.latitude || 0}%2C${this.location?.longitude || 0}` : null
    },
    wazeLink () {
      return this.location ? `https://www.waze.com/ul?ll=${this.location?.latitude || 0}%2C${this.location?.longitude || 0}&navigate=yes&zoom=20` : null
    },
    appleLink () {
      return this.location ? `https://maps.apple.com/?q=${encodeURIComponent(this.dialogTitle)}&sll=${this.location?.latitude || 0}%2C${this.location?.longitude || 0}&z=8` : null
    },
  },
  methods: {
    showing () {
      this.location = null
      this.$nextTick(this.$refs.map.refresh)
    },
    showDirections (dir) {
      this.directions = dir
    },
    close () {
      this.directions = {}
      this.$emit('close')
    }
  },
  watch: {
    visible (state) {
      if (state) {
        this.showing()
      }
    }
  }
}
</script>
