<template>
  <v-app>

    <v-app-bar app>

      <v-toolbar-title class="ml-3">Error</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click.stop="$store.dispatch('toggleRightDrawer')">
        <v-icon>menu</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main style="padding-top: 68px">
      <v-card dark color="error" max-width="640px" style="margin-right: auto; margin-left: auto;">
        <v-card-title>
          Hoppsan!
        </v-card-title>
        <v-card-text>
          <v-alert :value="true" type="error">
            <div class="body-1">Ett problem uppstod vid inläsning av programvaran!</div>
            <div class="body-1">Försök igen om en stund.</div>
            <div class="body-1">Kontakta {{supportEmail}} om problemen kvarstår.</div>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn text :href="homePage">
            Hemsidan
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text :href="`mailto:${supportEmail}`">
            Support
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-main>

    <v-navigation-drawer
      temporary
      :right="true"
      :width="320"
      :value="rightDrawer"
      fixed
      app
      @input="rightDrawerStatus($event)"
    >

      <v-card>
        <v-card-title>
          Error details
          <v-spacer></v-spacer>
          <v-btn icon @click="rightDrawerStatus(!rightDrawer)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="text-pre-wrap">
            {{error}}
          </div>
        </v-card-text>
      </v-card>

    </v-navigation-drawer>

    <AppFooter></AppFooter>

    <v-btn
      color="secondary"
      dark
      small
      bottom
      right
      fixed
      fab
      href="/help"
    >
      <span class="headline">?</span>
    </v-btn>

  </v-app>
</template>

<script>
import AppFooter from '@/components/AppFooter'

export default {
  name: 'Error',
  components: { AppFooter },
  props: ['error'],
  data () {
    return {
      homePage: 'https://inspectech.se/',
      supportEmail: 'support@inspectech.se'
    }
  },
  computed: {
    rightDrawer () {
      return this.$store.state.rightDrawer
    }
  },
  methods: {
    rightDrawerStatus (event) {
      this.$store.commit('setRightDrawer', event)
    },
  }
}
</script>
