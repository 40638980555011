<template>
  <v-avatar :size="size" :color="avatarColor" :title="avatarTitle" v-bind="$attrs" v-on="$listeners">
    <v-img v-if="avatarSrc" :src="avatarSrc"/>
    <span v-else :class="textClass" v-text="initials"></span>
  </v-avatar>
</template>

<script>
export default {
  name: "CrmAvatar",
  props: {
    value: {
      type: Object,
      required: true
    },
    src: {
    },
    property: {
      type: String,
      default: 'picture'
    },
    assets: {
      type: String
    },
    size: {
      type: Number,
      default: 28
    }
  },
  computed: {
    assetsPath () {
      return this.assets || `${process.env.VUE_APP_API}/${this.$crm.tenant}/v1/content/assets`
    },
    imageName() {
      return this.value && this.value[this.property]
    },
    avatarSrc () {
      if(this.src !== undefined) {
        return this.src
      }
      const value = this.value
      const tenant = this.$crm.tenant
      const img = this.imageName
      if(tenant && img) {
        return `${this.assetsPath}/${img}?variant=thumbnail`
      }
      return null
    },
    avatarColor () {
      return this.avatarSrc ? '#ffffff' : 'primary'
    },
    avatarTitle () {
      return this.value.name
    },
    textClass () {
      return this.size < 30 ? ['text-body-2', 'white--text'] : (this.size > 100 ? ['text-h3', 'white--text'] : ['text-body-1', 'white--text'])
    },
    initials () {
      const value = this.value
      if (value.initials) {
        return value.initials
      }
      if (value.firstName && value.lastName) {
        return value.firstName[0].toUpperCase() + value.lastName[0].toUpperCase()
      }
      if (value.name) {
        const names = value.name.trim().split(' ', 2)
        if (names.length > 1) {
          const firstName = names[0] || ''
          const lastName = names[1]  || ''
          return firstName[0].toUpperCase() + lastName[0].toUpperCase()
        }
        if (value.name.length > 1) {
          return value.name.substring(0, 1).toUpperCase()
        }
        return value.name.toUpperCase()
      }
      return '?'
    },
  },
}
</script>
