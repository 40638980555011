const state = {
  loading: 0,
  rightDrawer: false,
  breadcrumbs: [],
  history: [],
  itemsPerPage: 10,
  itemsPerPageOptions: [5, 10, 25, 50, 100, -1],
  features: [],
  favorites: {},
  assets: null,
  staticPath: null,
  homeView: null,
  currentOrders: null,
  currentInspections: null,
  calendarType: 'month',
  calendarEvents: null,
  lastSavedRemark: null,
  inspectionMetadata: null,
  inspectionSearch: {},
  orderSearch: {},
  clipboard: null,
  appointedByOptions: [
    { name: null, title: '' },
    { name: 'customer', title: 'Utsedd av uppdragsgivaren' },
    { name: 'order', title: 'Utsedd av beställaren' },
    { name: 'order-speech', title: 'Utsedd att föra beställarens talan' },
    { name: 'contractor', title: 'Utsedd av entreprenören' },
    { name: 'contractor-speech', title: 'Utsedd att föra entreprenörens talan' },
    { name: 'contractor2', title: 'Utsedd av underentreprenören' },
    { name: 'contractor2-speech', title: 'Utsedd att föra underentreprenörens talan' },
    { name: 'inspector', title: 'Utsedd av besiktningsman' },
    { name: 'mutal', title: 'Utsedd av parterna gemensamt' }
  ]
}

export default state
