import C from '@/store/constants'

export default {
  namespaced: true,
  state: {},
  actions: {
    getEmailTemplates ({ dispatch }, query) {
      let path = '/templates'
      if (query?.tag) {
        path = path + '?tag=' + encodeURIComponent(query.tag)
      }
      return new Promise(function (resolve, reject) {
        return dispatch('API', C.service.EMAIL_SERVICE, { root: true })
            .then(api => api.get(path))
            .then(response => resolve(response.data))
            .catch(err => reject(err))
      })
    },

    getEmailTemplate ({ dispatch }, id) {
      return new Promise(function (resolve, reject) {
        return dispatch('API', C.service.EMAIL_SERVICE, { root: true })
            .then(api => api.get('/templates/' + encodeURIComponent(id)))
            .then(response => resolve(response.data))
            .catch(err => reject(err))
      })
    },

    saveEmailTemplate ({ dispatch }, data) {
      return new Promise(function (resolve, reject) {
        return dispatch('API', C.service.EMAIL_SERVICE, { root: true })
            .then(api => {
              if(data.id) {
                return api.put('/templates/' + encodeURIComponent(data.id), data)
              } else {
                return api.post('/templates', data)
              }
            })
            .then(response => resolve(response.data))
            .catch(err => reject(err))
      })
    },

    deleteEmailTemplate ({ dispatch }, id) {
      return new Promise(function (resolve, reject) {
        return dispatch('API', C.service.EMAIL_SERVICE, { root: true })
            .then(api => api.delete('/templates/' + encodeURIComponent(id)))
            .then(response => resolve(response.data))
            .catch(err => reject(err))
      })
    },

    parseEmailTemplate ({ dispatch }, { id, data}) {
      return new Promise(function (resolve, reject) {
        return dispatch('API', C.service.EMAIL_SERVICE, { root: true })
            .then(api => api.post('/templates/' + encodeURIComponent(id), data))
            .then(response => resolve(response.data))
            .catch(err => reject(err))
      })
    },

    sendEmail ({ dispatch }, data) {
      return new Promise(function (resolve, reject) {
        return dispatch('API', C.service.EMAIL_SERVICE, { root: true })
            .then(api => api.post('/messages', data))
            .then(response => resolve(response.data))
            .catch(err => reject(err))
      })
    },
  }
}
