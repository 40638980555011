import C from '@/store/constants'

export default {

  status ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', C.service.VB_SERVICE, { root: true })
        .then(api => api.get('/fortnox'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  activate ({ dispatch }, args) {
    return new Promise(function (resolve, reject) {
      dispatch('API', C.service.VB_SERVICE, { root: true })
        .then(api => api.post('/fortnox', args))
        .then(response => {
          console.log(response)
          resolve(response.data)
        })
        .catch(err => reject(err))
    })
  },

  uninstall ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', C.service.VB_SERVICE, { root: true })
        .then(api => api.delete('/fortnox'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  activation ({ dispatch }, args) {
    return new Promise(function (resolve, reject) {
      dispatch('API', C.service.VB_SERVICE, { root: true })
        .then(api => api.post('/fortnox/activation', args))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getCompanyInformation ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', C.service.VB_SERVICE, { root: true })
        .then(api => api.get('/fortnox/companyinformation'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getAllCustomers ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', C.service.VB_SERVICE, { root: true })
        .then(api => api.get('/fortnox/customers'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getCustomersByName ({ dispatch }, name) {
    return new Promise(function (resolve, reject) {
      dispatch('API', C.service.VB_SERVICE, { root: true })
        .then(api => api.get('/fortnox/customers?name=' + encodeURIComponent(name)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getCustomersByEmail ({ dispatch }, { name, email }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', C.service.VB_SERVICE, { root: true })
        .then(api => api.get('/fortnox/customers?name=' + encodeURIComponent(name) + "&email=" + encodeURIComponent(email)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getCustomersByPostalCode ({ dispatch }, { name, postalCode }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', C.service.VB_SERVICE, { root: true })
        .then(api => api.get('/fortnox/customers?name=' + encodeURIComponent(name) + "&postalCode=" + encodeURIComponent(postalCode)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getCustomer ({ dispatch }, number) {
    return new Promise(function (resolve, reject) {
      dispatch('API', C.service.VB_SERVICE, { root: true })
        .then(api => api.get('/fortnox/customers/' + encodeURIComponent(number)))
        .then(response => resolve(response.data))
        .catch(err => resolve(null))
    })
  },
  createInspectionInvoices({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', C.service.VB_SERVICE, { root: true })
        .then(api => api.post('/fortnox/invoices/', data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  /*
  createInvoice({ dispatch }, ids) {
    return new Promise(function (resolve, reject) {
      dispatch('API', C.service.VB_SERVICE, { root: true })
        .then(api => api.post('/fortnox/invoices/', { id: ids }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  }
  */
}
