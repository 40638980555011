import EventBus from "@/eventbus";

const ACCOUNT_SERVICE = 'account'

export const actions = {
  createTenant ({ state, dispatch }, tenant) {
    let params = Object.assign({}, tenant)
    if(!params.email) {
      params.email = state.user.email
    }
    return new Promise(function (resolve, reject) {
      dispatch('API', null, { root: true })
        .then(api => api.post('/v1/account/organizations', params))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  selectTenant ({ state, dispatch }, { id, reload }) {
    if(!reload) {
      const currentTenant = state.tenant
      if (currentTenant?.id === id) {
        return Promise.resolve(currentTenant)
      }
    }
    return dispatch('initApplication', id, { root: true })
      .then(data => (data.tenant))
  },

  getAccounts ({ commit, dispatch }) {
    return new Promise(function (resolve) {
      dispatch('API', null, { root: true })
        .then(api => api.get('/v1/account/user/accounts'))
        .then(response => {
          const accounts = response.data
          commit('setAccounts', accounts)
          resolve(accounts)
        })
        .catch(err => {
          errorHandler('app.error.account.get', err)
          resolve([])
        })
    })
  },

  getTenantUsers ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.get('/user/organization/users'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  getAccount({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => {
          api.get('/organizations/' + encodeURIComponent(api.tenant) + '/users/' + encodeURIComponent(id))
            .then(response => resolve(response.data))
            .catch(err => resolve(err))
        })
        .catch(err => reject(err))
    })
  },
  updateAccount ({ dispatch }, account) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.post('/accounts/' + encodeURIComponent(account.id), account))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteAccount ({ dispatch }, account) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.delete('/organizations/' + encodeURIComponent(api.tenant) + '/users/' + encodeURIComponent(account.id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  lookupUser({ dispatch }, sub) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => {
          api.get('/accounts?sub=' + encodeURIComponent(sub))
            .then(response => resolve(response.data))
            .catch(err => resolve(err))
        })
        .catch(err => reject(err))
    })
  },
  fetchTenantRoles ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.get('/user/organization/roles'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchTenantUsers ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.get('/organizations/' + encodeURIComponent(api.tenant) + '/users'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchTenantGroups ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.get('/organizations/' + encodeURIComponent(api.tenant) + '/groups'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  saveTenantGroup({ dispatch }, group) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => {
          if(group.id) {
            return api.put('/organizations/' + encodeURIComponent(api.tenant) + '/groups/' + encodeURIComponent(group.id), { ...group, tenant: api.tenant })
          }
          return api.post('/organizations/' + encodeURIComponent(api.tenant) + '/groups', { ...group, tenant: api.tenant })
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteTenantGroup({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.delete('/organizations/' + encodeURIComponent(api.tenant) + '/groups/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchTenantInvitations ({ dispatch, state }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.get('/organizations/' + encodeURIComponent(api.tenant) + '/invitations'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteTenantInvitation({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.delete('/organizations/' + encodeURIComponent(api.tenant) + '/invitations/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  addTenantRole({ dispatch }, role) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.post('/organizations/' + encodeURIComponent(api.tenant) + '/roles', { ...role, tenant: api.tenant }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  updateTenantRole({ dispatch }, role) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.put('/organizations/' + encodeURIComponent(api.tenant) + '/roles/' + encodeURIComponent(role.id), { ...role, tenant: api.tenant }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteTenantRole({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.delete('/organizations/' + encodeURIComponent(api.tenant) + '/roles/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  inviteUser ({ dispatch }, params) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.post('/user/organization/invitations', params))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  inviteUserGroup ({ dispatch }, params) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.post('/organizations/' + encodeURIComponent(api.tenant) + '/groups/' + encodeURIComponent(params.group), params))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  getUserGroups ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.get('/user/groups'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  leaveUserGroup ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.delete('/user/groups/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  getGroupMembers ({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.get('/organizations/' + encodeURIComponent(api.tenant) + '/groups/' + encodeURIComponent(id) + '/members'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchInvitations ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', null, { root: true })
        .then(api => api.get('/v1/account/user/organization/invitations'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  acceptInvitation ({ dispatch }, params) {
    return new Promise(function (resolve, reject) {
      dispatch('API', null, { root: true })
        .then(api => api.post('/v1/account/user/organization/invitations/' + encodeURIComponent(params.id), {}))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  denyInvitation ({ dispatch }, params) {
    return new Promise(function (resolve, reject) {
      dispatch('API', null, { root: true })
        .then(api => api.delete('/v1/account/user/organization/invitations/' + encodeURIComponent(params.id), {}))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchTenant ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.get('/user/organization'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  updateTenant ({ dispatch }, org) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.post('/user/organization', org))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  updateImage ({ dispatch }, { file, path, width, height }) {
    const segments = path.split('/')
    const filename = segments.pop()
    const form = new FormData()
    form.append('file', file, filename)
    return dispatch('content/uploadContent', { path: segments.join('/'), access: 'ANONYMOUS', width: width, height: height, files: [form.get('file')] }, { root: true })
      .then(result => result.pop())
  },

  getPermissions ({ dispatch }, { target }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.get('/permissions/' + encodeURIComponent(target)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  setPermissions ({ dispatch }, permissions) {
    return new Promise(function (resolve, reject) {
      dispatch('API', ACCOUNT_SERVICE, { root: true })
        .then(api => api.post('/permissions', permissions))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
}
