<template>
    <v-dialog :value="visible" max-width="640px" scrollable persistent @keydown.esc="$emit('close')">
        <v-card>
            <v-card-title>
                <span class="headline">Notiser</span>
            </v-card-title>

            <v-card-text style="height: 480px;">

                <v-list two-line>
                    <template v-for="(item, index) in items">
                        <v-subheader
                                v-if="item.header"
                                :key="item.header"
                        >
                            {{ item.header }}
                        </v-subheader>

                        <v-divider
                                v-else-if="item.divider"
                                :inset="item.inset"
                                :key="index"
                        ></v-divider>

                        <v-list-item
                                v-else
                                :key="item.id"
                                @click="readNotification(item)"
                        >
                            <v-list-item-action>
                                <v-icon v-html="item.icon || (item.attachments ? 'cloud_download' : 'info_outline')"></v-icon>
                            </v-list-item-action>

                            <v-list-item-content :class="item.read ? null : 'font-weight-bold'">
                                <v-list-item-title v-html="item.subject"></v-list-item-title>
                                <v-list-item-subtitle v-html="item.body"></v-list-item-subtitle>
                                <v-list-item-subtitle>{{item.created | formatTimestampRelative}}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn icon @click.stop="removeNotification(item)"><v-icon small>cancel</v-icon></v-btn>
                            </v-list-item-action>

                        </v-list-item>
                    </template>
                </v-list>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text color="red darken-3" @click.native="$emit('reset')">{{$vuetify.lang.t('$vuetify.crm.notification.clearAll')}}</v-btn>

                <v-btn text @click.native="$emit('close')">{{$vuetify.lang.t('$vuetify.crm.default.button.close')}}</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CrmNotificationList',
  props: {
    visible: {
      type: Boolean
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
  }),
  computed: {},
  methods: {
    removeNotification (item) {
      this.$emit('remove', item)
    },
    readNotification (item) {
      this.$emit('read', item)
    }
  },
  watch: {
    items (list) {
      if (list === undefined || list.length < 1) {
        // Close dialog after last notification is removed.
        setTimeout(() => {
          this.$emit('close')
        }, 1000)
      }
    }
  },
  filters: {
    formatTimestampRelative (ts) {
      return ts ? moment(ts).fromNow() : ''
    }
  }
}
</script>

