const Admin = () => import(/* webpackChunkName: "admin" */ '../views/admin/Admin')

const ADMIN_ROLE = 'admin'

export default [

  // ---------- ADMIN ----------
  {
    path: '/:tenant/admin',
    name: 'admin',
    component: Admin,
    meta: {
      title: 'Administration',
      icon: 'mdi-settings',
      tenant: true,
      admin: true
    }
  },

  // ---------- Organisation ----------
  {
    path: '/:tenant/admin/org',
    name: 'admin-org',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/OrganizationProfile.vue'),
    meta: {
      title: 'Inställningar',
      icon: 'mdi-cogs',
      tenant: true,
      admin: true,
      organization: true
    }
  },
  {
    path: '/:tenant/admin/users',
    name: 'admin-users',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/OrganizationUsers.vue'),
    meta: {
      title: 'Användare',
      icon: 'mdi-account-multiple',
      tenant: true,
      admin: true,
      organization: true
    }
  },
  {
    path: '/:tenant/admin/users/:id',
    name: 'edit-user-account',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/UserAdminView.vue'),
    props: true,
    meta: {
      title: 'Användare',
      icon: 'mdi-account-multiple',
      tenant: true,
      role: ADMIN_ROLE
    }
  },
  {
    path: '/:tenant/admin/roles',
    name: 'admin-org-roles',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/UserRoleAdminView.vue'),
    meta: {
      title: 'Användarroller',
      icon: 'mdi-account-box',
      tenant: true,
      role: ADMIN_ROLE,
      organization: true
    }
  },

  {
    path: '/:tenant/admin/network',
    name: 'admin-network',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/OrganizationNetwork.vue'),
    meta: {
      title: 'Samarbeten',
      icon: 'mdi-dots-hexagon',
      tenant: true,
      role: ADMIN_ROLE,
      organization: true
    }
  },

  // ---------- Bokningskalender ----------
  {
    path: '/:tenant/admin/order',
    name: 'admin-order',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/PublicOrderSettings.vue'),
    meta: {
      title: 'Beställningssidan',
      icon: '$order',
      tenant: true,
      admin: true,
      organization: true
    }
  },
  {
    path: '/:tenant/admin/order-type',
    name: 'order-type',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/orderType/OrderTypeListView'),
    meta: {
      title: 'Beställningsalternativ',
      icon: 'mdi-shape',
      tenant: true,
      admin: true,
      organization: true
    }
  },
  {
    path: '/:tenant/admin/order-type/:id',
    name: 'order-type-admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/orderType/OrderTypeAdminView.vue'),
    props: true,
    meta: {
      title: 'Beställningsalternativ',
      icon: 'mdi-shape',
      tenant: true,
    }
  },
  {
    path: '/:tenant/admin/calendar',
    name: 'admin-calendar',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/PublicCalendarSettings.vue'),
    meta: {
      title: 'Bokningskalender',
      icon: '$calendar',
      tenant: true,
      admin: true,
      organization: true
    }
  },

  // ---------- Projekt / Besiktningar ----------

  {
    path: '/:tenant/admin/inspection-type',
    name: 'inspection-type-list',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/inspectionType/InspectionTypeListView'),
    meta: {
      title: 'Uppdragstyper',
      icon: 'mdi-file-find',
      tenant: true,
      admin: true,
      inspection: true
    }
  },
  {
    path: '/:tenant/admin/inspection-type/:id',
    name: 'inspection-type',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/inspectionType/InspectionTypeAdmin'),
    meta: {
      title: 'Uppdragstyp',
      icon: 'mdi-file-find',
      tenant: true
    }
  },
  {
    path: '/:tenant/admin/inspection-status',
    name: 'inspection-status-list',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/inspectionStatus/InspectionStatusListView'),
    meta: {
      title: 'Uppdragsstatus',
      icon: 'mdi-state-machine',
      tenant: true,
      admin: true,
      inspection: true
    }
  },
  {
    path: '/:tenant/admin/inspection-status/:id',
    name: 'inspection-status',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/inspectionStatus/InspectionStatusAdmin'),
    meta: {
      title: 'Uppdragsstatus',
      icon: 'mdi-state-machine',
      tenant: true
    }
  },
  {
    path: '/:tenant/admin/contact-role',
    name: 'contact-role',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/contactRole/ContactRoleListView'),
    meta: {
      title: 'Personroller',
      icon: 'mdi-account-multiple',
      tenant: true,
      admin: true,
      inspection: true
    }
  },
  {
    path: '/:tenant/admin/contact-role/:id',
    name: 'contact-role-edit',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/contactRole/ContactRoleAdmin'),
    meta: {
      title: 'Personroll',
      icon: 'mdi-account-multiple',
      tenant: true,
      admin: true,
    }
  },
  {
    path: '/:tenant/admin/inspection-template',
    name: 'inspection-template',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/inspection/InspectionTemplateList'),
    meta: {
      title: 'Formulär',
      icon: 'mdi-palette',
      tenant: true,
      admin: true,
      inspection: true
    }
  },
  {
    path: '/:tenant/admin/inspection-template/:id',
    name: 'inspection-template-admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/inspection/InspectionTemplateAdmin'),
    meta: {
      title: 'Formulär',
      icon: 'mdi-palette',
      tenant: true,
      admin: true,
    }
  },
  {
    path: '/:tenant/admin/form',
    name: 'form-admin-list',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/FormAdminList'),
    meta: {
      title: 'Attribut',
      icon: 'mdi-form-select',
      tenant: true,
      admin: true,
      inspection: true
    }
  },
  {
    path: '/:tenant/admin/form/:id',
    name: 'form-admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/FormAdminView'),
    props: true,
    meta: {
      title: 'Attribut',
      icon: 'mdi-form-select',
      tenant: true,
      admin: true,
    }
  },
  {
    path: '/:tenant/admin/remarks',
    name: 'remarks',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/RemarkAdminView'),
    meta: {
      title: 'Sparade noteringar',
      icon: 'mdi-message-bulleted',
      tenant: true,
      admin: true,
      inspection: true
    }
  },

  // ---------- Övrigt ----------
  {
    path: '/:tenant/admin/templates/doc',
    name: 'document-templates',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/DocumentTemplateView'),
    meta: {
      title: 'Dokumentmallar',
      icon: 'mdi-text-box-multiple',
      tenant: true,
      admin: true,
      misc: true
    }
  },
  {
    path: '/:tenant/admin/templates/doc/:id',
    name: 'document-template-edit',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/DocumentTemplateAdmin'),
    meta: {
      title: 'Redigera dokumentmall',
      icon: 'mdi-file-document-box',
      tenant: true,
      admin: true
    }
  },
  {
    path: '/:tenant/admin/templates/email',
    name: 'email-templates',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/EmailTemplateView'),
    meta: {
      title: 'E-postmallar',
      icon: '$email',
      tenant: true,
      admin: true,
      misc: true
    }
  },
  {
    path: '/:tenant/admin/templates/email/:id',
    name: 'email-template-edit',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/EmailTemplateAdmin'),
    meta: {
      title: 'Redigera e-postmall',
      icon: '$email',
      tenant: true,
      admin: true
    }
  },
  {
    path: '/:tenant/admin/sequences',
    name: 'sequences',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/SequenceNumberListView'),
    meta: {
      title: 'Nummerserier',
      icon: '$sequence',
      tenant: true,
      admin: true,
      misc: true
    }
  },

  // Fortnox Integration
  {
    path: '/:tenant/admin/fortnox',
    name: 'fortnox',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/fortnox/FortnoxConfigurationView'),
    props: route => ({ code: route.query.code, state: route.query.state }),
    meta: {
      title: 'Fortnox integration',
      icon: '$money',
      tenant: true,
      admin: false, /* This must be false, otherwise Fortnox activation will fail. :-( */
      misc: true,
      feature: 'fortnox'
    }
  },
  {
    path: '/fortnox/activation',
    name: 'fortnox-activation-entrypoint',
    redirect: to => {
      return { name: 'fortnox', query: to.query, params: { tenant: to.query.state }}
    },
  },

  // Visma eEconomy Integration
  {
    path: '/:tenant/admin/visma',
    name: 'visma',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/visma/VismaConfigurationView'),
    props: route => ({ code: route.query.code, state: route.query.state }),
    meta: {
      title: 'Visma integration',
      icon: '$money',
      tenant: true,
      admin: false, /* This must be false, otherwise Visma activation will fail. :-( */
      misc: true,
      feature: 'visma'
    }
  },
  {
    path: '/visma/activation',
    name: 'visma-activation-entrypoint',
    redirect: to => {
      return { name: 'visma', query: to.query, params: { tenant: to.query.state }}
    },
  },

  {
    path: '/:tenant/admin/check',
    name: 'metadata-check',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/MetadataCheck.vue'),
    meta: {
      title: 'Kontrollera inställningar',
      icon: 'mdi-cog-outline',
      tenant: true,
      admin: true,
      misc: true
    }
  },
]
