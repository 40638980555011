/* eslint-disable no-console */
import Constants from './constants'
import moment from 'moment'
import C from '@/store/constants'
import axios from 'axios'
import EventBus from '@/eventbus'

const VB_SERVICE_NAME = C.service.VB_SERVICE
const INSPECTION_SERVICE_NAME = C.service.INSPECTION_SERVICE
const CONTACT_SERVICE_NAME = C.service.CONTACT_SERVICE
const CONTENT_SERVICE_NAME = C.service.CONTENT_SERVICE
const SPEECH_SERVICE_NAME = C.service.SPEECH_SERVICE
const SEQUENCE_SERVICE_NAME = C.service.SEQUENCE_SERVICE
const EMAIL_SERVICE_NAME = C.service.EMAIL_SERVICE

const PARTNER_TAG = 'partner'

const errorHandler = (msg, err) => EventBus.$emit('error', { message: msg, error: err })

function contactQuery (number) {
  let query = 'query{\ncontacts('
  if (number) {
    query += 'number:"'
    query += number
    query += '"'
  }

  query += ')\n{\nid\ntype\nnumber\nname\naddresses{type address postalCode city region country}\n}\n}'

  return query
}

const findRole = (array, role) => {
  if (!array) {
    return {}
  }
  return array.find((c) => c.role === role) || {}
}

const JWT_COOKIE_NAME = 'JWT'

const setCookie = (name, value, days = 1, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path + process.env.VUE_APP_COOKIE_SECURE
}

const actions = {
  API ({ state, getters, dispatch }, serviceName) {
    let tenant = ''
    let prefix = ''
    if(serviceName) {
      const tenantId = getters['account/tenantId']
      if(tenantId) {
        tenant = tenantId
        prefix = '/' + encodeURIComponent(tenant)
      } else {
        return Promise.reject(new Error('API call to ' + serviceName + ' without tenant'))
      }
      prefix = prefix + ('/v1/' + serviceName)
    }
    const remoteCall = function(cb) {
      return dispatch('startLoading')
        .then(() => EventBus.$keycloak.updateToken(30))
        .then(refreshed => {
          if (refreshed) {
            console.log('token refreshed prior to request')
            setCookie(JWT_COOKIE_NAME, EventBus.$keycloak.token)
          }
          return refreshed
        })
        .then(cb)
        .finally(() => dispatch('stopLoading'))
    }
    return Promise.resolve({
      http: axios,
      tenant: tenant,
      url: prefix,
      head: (url, config) => remoteCall(() => axios.head(prefix + url, config)),
      get: (url, config) => remoteCall(() => axios.get(prefix + url, config)),
      post: (url, body, config) => remoteCall(() => axios.post(prefix + url, body, config)),
      put: (url, body, config) => remoteCall(() => axios.put(prefix + url, body, config)),
      delete: (url, config) => remoteCall(() => axios.delete(prefix + url, config))
    })
  },

  initApplication({ dispatch, commit, state, getters }, tenantId) {
    return new Promise(function(resolve, reject) {
      return dispatch('API')
        .then(api => api.get('/' + encodeURIComponent(tenantId) + '/v1/vb'))
        .then(response => {
          const data = response.data
          const tenant = data.tenant
          const currentTenant = getters['account/tenantId']
          if (!tenant || (currentTenant !== tenant.id)) {
            commit('resetState')
          }
          commit('setAssetsTenant', data.assets)
          commit('setFeatures', data.features || [])
          commit('account/setAccounts', data.accounts || [])
          commit('account/setCurrentTenant', tenant)
          commit('account/setCurrentAccount', data.user)
          commit('account/setCurrentRoles', data.roles || [])
          commit('account/setUserSettings', data.user.properties || {})
          commit('account/setPermissions', data.permissions || [])
          if (tenant) {
            EventBus.$emit('application:init', data)
          }
          resolve(data)
        })
        .catch(err => reject(err))
    })
  },
  startLoading ({ commit }) {
    commit('setLoading', true)
  },
  stopLoading ({ commit }) {
    commit('setLoading', false)
  },
  reload ({ commit }, notification) {
    // Called by websocket/notification when backend sends a reload notification.
    commit('reload', notification)
    return Promise.resolve(notification)
  },
  breadcrumbs ({ getters, commit }, arr) {
    const tenant = getters['account/tenantId']
    commit('setBreadcrumbs', arr.map(bc => {
      if(typeof(bc.to) === 'string') {
        const prefix = tenant ? '/' + tenant : ''
        bc.to = prefix + bc.to
      } else {
        const to = { ...bc.to }
        if(tenant && ! to.params?.tenant) {
          if(to.params) {
            to.params.tenant = tenant
          } else {
            to.params = { tenant }
          }
        }
        bc.to = to
      }
      return { text: bc.text, to: bc.to }
    }))
  },
  history ({ state, rootState, commit }, arg) {
    const to = arg.to
    if (typeof to === 'string' || to instanceof String) {
      arg.to = `/${rootState.account.tenant.id}${to}`
    }
    return commit('addHistory', arg)
  },
  copyObjectToClipboard({ state, commit }, arg) {
    return commit('setClipboardObject', arg)
  },
  getObjectFromClipboard({ state, commit }, clear) {
    return new Promise(function (resolve, reject) {
      const value = state.clipboard
      if (value !== undefined && value !== null) {
        if (clear) {
          commit('setClipboardObject', null)
        }
        resolve(value)
      } else {
        reject(new Error('No data in clipboard'))
      }
    })
  },
  clearObjectClipboard({ commit }) {
    return commit('setClipboardObject', null)
  },
  toggleRightDrawer ({ state, commit }) {
    return commit('setRightDrawer', !state.rightDrawer)
  },
  getUserProfile ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.get('/admin/profile'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  updateUserProfile ({ dispatch }, profile) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.post('/admin/profile', profile))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getUserImages ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.get('/admin/profile/images'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getInspectionLinks ({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.get('/admin/inspections/' + encodeURIComponent(id) + '/links'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getUserFeatures ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.get('/admin/profile/features'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  addUserFeature ({ dispatch }, feature) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.post('/admin/profile/features', feature))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  removeUserFeature ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.delete('/admin/profile/features/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getUserFeatureCatalog ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.get('/admin/features'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getBillingCatalog({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.get('/billing/catalog'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  checkMetadata ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/metadata'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  fixMetadata ({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/metadata', data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  // eslint-disable-next-line no-unused-vars
  metadataToSelect ({ dispatch }, items) {
    return items.map(entry => {
      return { value: entry.value, text: entry.label, info: entry.info, icon: entry.icon }
    })
  },

  // PROJECTS - MAIN VIEW
  fetchProjectDetails ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/projects/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  getProjects ({ dispatch }, { active, inspector }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME)
        .then(api => api.post('/projects/search', { inspector, active: true }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  saveProject ({ dispatch }, { id, data }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.put('/projects/' + encodeURIComponent(id), data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  cloneProject ({ dispatch }, { id, items }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME)
        .then(api => api.post('/projects/' + encodeURIComponent(id) + "?state=" + items.join(',')))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchProjectTypes ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/projects/types'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  getProjectType ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/projects/types/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  saveProjectType ({ dispatch }, { data }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => data.id ? api.put('/projects/types/' + encodeURIComponent(data.id), data) : api.post('/projects/types', data))
        .then(response => resolve(response.data))
        .then(dispatch('clearInspectionMetadata'))
        .catch(err => reject(err))
    })
  },
  deleteProjectType ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.delete('/projects/types/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .then(dispatch('clearInspectionMetadata'))
        .catch(err => reject(err))
    })
  },
  getEstates ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/projects/' + encodeURIComponent(id) + '/estates'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  addEstateToProject ({ dispatch }, { id, data }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.post('/projects/' + encodeURIComponent(id) + '/estates', data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteEstate ({ dispatch }, { id, estate }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.delete('/projects/' + encodeURIComponent(id) + '/estates/' + encodeURIComponent(estate)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  copyEstate ({ dispatch }, { id, value, details }) {
    return new Promise(function (resolve, reject) {
      const state = details ? `?state=${encodeURIComponent(details.join(','))}` : ''
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.post('/projects/' + encodeURIComponent(id) + '/estates/' + encodeURIComponent(value.id) + state, value))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  moveEstate ({ dispatch }, { id, value }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.put('/projects/' + encodeURIComponent(id) + '/estates', value))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  addInspectionToProject ({ dispatch }, { id, data }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.post('/projects/' + encodeURIComponent(id) + '/inspections', data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchInspectionsByProject ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/projects/' + encodeURIComponent(id) + '/inspections'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  searchInspection({ dispatch }, query) {
    return new Promise(function (resolve, reject) {
      dispatch('API', C.service.INSPECTION_SERVICE, { root: true })
        .then(api => {
          if(typeof query === 'object') {
            return api.post('/inspections/search', query)
          }
          return api.get('/inspections/search/' + encodeURIComponent(query))
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchCurrentInspections ({ dispatch }, { inspector }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.post('/inspections/search', { inspector, active: true }))
        .then(response => {
          const result = response.data.items.map((m) => ({
            ...m,
            customer: findRole(m.contacts, Constants.CONTACT_ROLE_CUSTOMER).name
          }))
          resolve(result)
        })
        .catch(err => reject(err))
    })
  },
  fetchInspection ({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/inspections/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  createInspections ({ dispatch }, data ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/inspections', data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  saveInspection ({ dispatch }, { id, body }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.put('/inspections/' + encodeURIComponent(id), body))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  copyInspection ({ dispatch }, { id, estate, value, details }) {
    return new Promise(function (resolve, reject) {
      const state = details ? `?state=${encodeURIComponent(details.join(','))}` : ''
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.post('/projects/' + encodeURIComponent(id) + '/estates/' + encodeURIComponent(estate) + '/inspections/' + encodeURIComponent(value.id) + state, value))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  copyObject ({ dispatch }, { id, inspection, value, details }) {
    return new Promise(function (resolve, reject) {
      const state = details ? `?state=${encodeURIComponent(details.join(','))}` : ''
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.post('/inspections/' + encodeURIComponent(inspection) + '/objects/' + encodeURIComponent(value.id) + state, value))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteProject({ dispatch }, id ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.delete('/projects/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteInspection({ dispatch }, id ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.delete('/inspections/' + encodeURIComponent(id) + '?hard=true'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteInspectionObject({ dispatch }, { id, object } ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.delete('/inspections/' + encodeURIComponent(id) + '/objects/' + encodeURIComponent(object)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  bulkUpdateInspections ({ dispatch }, command) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.post('/inspections', command))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  updateInspectionStatus ({ dispatch }, { id, status }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.put('/inspections/' + encodeURIComponent(id) + '/status', { value: status }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  updateInspectionAttribute ({ dispatch }, { id, name, value }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.put('/inspections/' + encodeURIComponent(id) + '/attributes/' + encodeURIComponent(name), { value }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  addInspectionTag ({ dispatch }, { id, tag }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.post('/inspections/' + encodeURIComponent(id) + '/tags/' + encodeURIComponent(tag)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  removeInspectionTag ({ dispatch }, { id, tag }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.delete('/inspections/' + encodeURIComponent(id) + '/tags/' + encodeURIComponent(tag)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  addRelatedInspection ({ dispatch }, { id, body }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.post('/inspections/' + encodeURIComponent(id) + '/related', body))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  saveEstate ({ dispatch }, { id, data }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.put('/projects/' + encodeURIComponent(id) + '/estates/' + encodeURIComponent(data.id), data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  saveContactInfo ({ dispatch }, { id, body }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.put('/inspections/' + encodeURIComponent(id) + '/contacts', body))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  removeContact ({ dispatch }, { id, contactId }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.delete('/inspections/' + encodeURIComponent(id) + '/contacts/' + encodeURIComponent(contactId)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  saveObjectInfo ({ dispatch }, { id, object }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => {
          const objectId = object.id
          if (objectId) {
            return api.put('/inspections/' + encodeURIComponent(id) + '/objects/' + encodeURIComponent(objectId), object)
              .then(response => resolve(response.data))
          } else {
            return api.post('/inspections/' + encodeURIComponent(id) + '/objects', object)
              .then(response => resolve(response.data))
          }
        })
        .catch(err => reject(err))
    })
  },
  fetchRemarks ({ dispatch }, { id, objectId }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/inspections/' + encodeURIComponent(id) + '/objects/' + encodeURIComponent(objectId) + '/remarks'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  setLastSavedRemark({ commit }, item) {
    commit('setLastSavedRemark', item)
    return Promise.resolve(item)
  },
  saveRemark ({ dispatch }, { id, objectId, remark }) {
    if (remark.id) {
      return new Promise(function (resolve, reject) {
        dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
          .then(api => api.put('/inspections/' + encodeURIComponent(id) + '/objects/' + encodeURIComponent(objectId) + '/remarks/' + encodeURIComponent(remark.id), remark))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    }
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.post('/inspections/' + encodeURIComponent(id) + '/objects/' + encodeURIComponent(objectId) + '/remarks', remark))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  removeRemark ({ dispatch }, { id, objectId, remarkId }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.delete('/inspections/' + encodeURIComponent(id) + '/objects/' + encodeURIComponent(objectId) + '/remarks/' + encodeURIComponent(remarkId)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  findRemarkValues ({ dispatch }, { field, query }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.post('/remarks/values/' + encodeURIComponent(field), query))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  bulkUpdateRemarks({ dispatch}, { id, objectId, items }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.put('/inspections/' + encodeURIComponent(id) + '/objects/' + encodeURIComponent(objectId) + '/remarks', { items }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  searchCommonRemarks ({ dispatch }, query) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.post('/remarks/common/query', query || {}))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  saveCommonRemark ({ dispatch }, remark) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => {
          const id = remark.id
          if (id) {
            return api.put('/remarks/common/' + encodeURIComponent(id), remark)
          } else {
            return api.post('/remarks/common', remark)
          }
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  removeCommonRemark ({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.delete('/remarks/common/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteCommonRemarks ({ dispatch }, ids) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.post('/remarks/common/delete', { delete: ids }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  fetchTemplateRemarks ({ dispatch }, target) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/remarks/templates/' + encodeURIComponent(target)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  saveTemplateRemark ({ dispatch }, { type, remark }) {
    const target = 'inspectionType:' + type
    const payload = { ...remark, target: target }
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => {
          const id = remark.id
          if (id) {
            return api.put('/remarks/templates/' + encodeURIComponent(id), payload)
          } else {
            return api.post('/remarks/templates', payload)
          }
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  removeTemplateRemark ({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.delete('/remarks/templates/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  // eslint-disable-next-line no-unused-vars
  transcribeAudio ({ dispatch }, { url, data }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', SPEECH_SERVICE_NAME, { root: true })
        .then(api => api.post('/', data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getInspectionMetadata ({ state, commit, dispatch }) {
    //if(state.inspectionMetadata) {
    //  return Promise.resolve(state.inspectionMetadata)
    //}
    //console.log("********************** GET METADATA *******************")
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/inspections'))
        .then(response => {
          const data = response.data
          commit('cacheInspectionMetadata', data)
          resolve(data)
        })
        .catch(err => reject(err))
    })
  },

  clearInspectionMetadata ({ commit }) {
    commit('cacheInspectionMetadata', null)
  },

  fetchInspectionTypes ({ commit, dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/types?archived=true'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getInspectionType({ dispatch }, id ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/types/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  saveInspectionType({ commit, dispatch }, type ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => {
          const url = '/types'
          if (type.id) {
            return api.put(url + '/' + encodeURIComponent(type.id), type)
          } else {
            return api.post(url, type)
          }
        })
        .then(response => resolve(response.data))
        .then(dispatch('clearInspectionMetadata'))
        .catch(err => reject(err))
    })
  },

  /* ----- Inspection Status ----- */
  fetchInspectionStatuses ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/statuses?archived=true'))
        .then(response => resolve(response.data.items))
        .catch(err => reject(err))
    })
  },

  getInspectionStatus({ dispatch }, id ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/statuses/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  saveInspectionStatus({ commit, dispatch }, status ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => {
          const url = '/statuses'
          if (status.id) {
            return api.put(url + '/' + encodeURIComponent(status.id), status)
          } else {
            return api.post(url, status)
          }
        })
        .then(response => resolve(response.data))
        .then(dispatch('clearInspectionMetadata'))
        .catch(err => reject(err))
    })
  },

  deleteInspectionStatus({ dispatch }, type ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.delete('/statuses/' + encodeURIComponent(type.id)))
        .then(response => resolve(response.data))
        .then(dispatch('clearInspectionMetadata'))
        .catch(err => reject(err))
    })
  },

  /* ----- Conatct Role ----- */
  fetchContactRoles ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/contact-roles?archived=true'))
        .then(response => resolve(response.data.items))
        .catch(err => reject(err))
    })
  },

  getContactRole({ dispatch }, id ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/contact-roles/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  saveContactRole({ commit, dispatch }, role ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => {
          const url = '/contact-roles'
          if (role.id) {
            return api.put(url + '/' + encodeURIComponent(role.id), role)
          } else {
            return api.post(url, role)
          }
        })
        .then(response => resolve(response.data))
        .then(dispatch('clearInspectionMetadata'))
        .catch(err => reject(err))
    })
  },

  deleteContactRole({ dispatch }, role ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.delete('/contact-roles/' + encodeURIComponent(role.id)))
        .then(response => resolve(response.data))
        .then(dispatch('clearInspectionMetadata'))
        .catch(err => reject(err))
    })
  },

  /* ----- Branch (Fack) ----- */

  fetchInspectionBranches ({ commit, dispatch }, id) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/inspections/' + encodeURIComponent(id) + '/branches'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getInspectionBranch({ dispatch }, { id, bid } ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/inspections/' + encodeURIComponent(id) + '/branches/' + encodeURIComponent(bid)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  saveInspectionBranch({ commit, dispatch }, { id, branch } ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => {
          const url = '/inspections/' + encodeURIComponent(id) + '/branches/'
          if (branch.id) {
            return api.put(url + encodeURIComponent(branch.id), branch)
          } else {
            return api.post(url, branch)
          }
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteInspectionBranch({ dispatch }, { id, branch } ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.delete('/inspections/' + encodeURIComponent(id) + '/branches/' + encodeURIComponent(branch.id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  deleteInspectionType({ dispatch }, type ) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.delete('/types/' + encodeURIComponent(type.id)))
        .then(response => resolve(response.data))
        .then(dispatch('clearInspectionMetadata'))
        .catch(err => reject(err))
    })
  },
  fetchObjectStatuses ({ dispatch }) {
    return dispatch('metadata/getMetadata', {
      group: Constants.METADATA_GROUP_INSPECTION,
      name: Constants.METADATA_OBJECT_STATUS
    }, { root: true })
      .then(list => dispatch('metadataToSelect', list, { root: true }))
  },

  // ORDER
  //
  clearOrder ({ commit }) {
    commit('RESET_ORDER')
  },

  fetchCurrentOrders ({ state, commit, dispatch }, { reload }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/order'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchOrder ({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/order/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  updateOrder ({ dispatch }, { id, order, accept }) {
    let url = '/admin/order/' + encodeURIComponent(id)
    if (accept !== undefined) {
      url = url + '?accept=' + accept
    }
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.put(url, order))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  shareOrder ({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/order/' + encodeURIComponent(data.id) + '/share', data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  checkAvailability ({ dispatch }, time) {
    const timestamp = time.format('YYYY-MM-DD')
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/check/' + encodeURIComponent(timestamp)))
        .then(response => {
          const items = response.data.items
          if (!items) {
            resolve([])
            return
          }
          const warnings = items.map((entry) => [entry.preferredDate
            ? moment(entry.preferredDate, 'YYYY-MM-DD[T]HH:mm:ss.SSSZ').format('[kl] HH:mm')
            : null, entry.estateLocation, entry.estateNumber].join(', '))
          resolve(warnings)
        })
        .catch(err => reject(err))
    })
  },
  fetchNextAvailableDate ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/public/order/nextDate?cb=' + (new Date()).getTime()))
        .then(response => resolve(response.data.date))
        .catch(err => reject(err))
    })
  },
  fetchOrderMetadata ({ commit, dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.get('/public/order?hidden=true'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchPartners ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', CONTACT_SERVICE_NAME, { root: true })
        .then(api => api.post('/search', { tags: PARTNER_TAG}))
        .then(response => resolve(response.data.items))
        .catch(err => reject(err))
    })
  },
  findContacts ({ dispatch }, query) {
    return new Promise(function (resolve, reject) {
      dispatch('API', CONTACT_SERVICE_NAME, { root: true })
        .then(api => api.post('/search', query))
        .then(response => resolve(response.data.items))
        .catch(err => reject(err))
    })
  },
  getContact ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', CONTACT_SERVICE_NAME, { root: true })
        .then(api => api.get('/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchOrderTypes ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/order-types'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  getOrderType ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/order-types/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  saveOrderType ({ dispatch }, { data }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => {
          if (data.id) {
            return api.put('/admin/order-types/' + encodeURIComponent(data.id), data)
          }
          return api.post('/admin/order-types', data)
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteOrderType ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.delete('/admin/order-types/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  getDistinctTags({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/inspections/tags'))
        .then(response => resolve(response.data.items))
        .catch(err => reject(err))
    })
  },
  getDistinctContactNotes({ dispatch }, { type }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', INSPECTION_SERVICE_NAME, { root: true })
        .then(api => api.get('/inspections/contacts/notes?type=' + encodeURIComponent(type)))
        .then(response => resolve(response.data.items))
        .catch(err => reject(err))
    })
  },
  searchOrders({ dispatch }, query) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => {
          return api.post('/admin/order/search', query)
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  // CALENDAR
  fetchCalendarEvents ({ dispatch }, query) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/events' + (query || '')))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchInspectionTemplates({ dispatch }, { id, type }) {
    const query = []
    if (type) {
      query.push('type=' + encodeURIComponent(type))
    }
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/inspections/' + encodeURIComponent(id) + '/export' + (query.length ? '?' + query.join('&') : '')))
        .then(response => resolve(response.data.items))
        .catch(err => reject(err))
    })
  },
  getBaseTemplates({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/documents/base-templates'))
        .then(response => resolve(response.data.items))
        .catch(err => reject(err))
    })
  },
  getDocumentTemplates({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/documents/templates'))
        .then(response => resolve(response.data.items))
        .catch(err => reject(err))
    })
  },
  getDocumentTemplate({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/documents/templates/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  saveDocumentTemplate({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/documents/templates', data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  uploadDocumentTemplates({ dispatch }, { path, files, author }) {
    return new Promise(function (resolve, reject) {
      const data = new FormData()
      for (var i = 0; i < files.length; i++) {
        let f = files[i]
        data.append('file', f)
      }
      const query = author ? '?author=true' : ''
      dispatch('API', CONTENT_SERVICE_NAME, { root: true })
        .then(api => api.post('/assets/' + encodeURI(path) + query, data, { headers: { 'Content-Type': 'multipart/form-data' } }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  updateDocumentTemplate ({ dispatch }, { path, file }) {
    return new Promise(function (resolve, reject) {
      const data = new FormData()
      data.append('file', file)
      dispatch('API', CONTENT_SERVICE_NAME, { root: true })
        .then(api => api.post('/assets/' + encodeURI(path), data, { headers: { 'Content-Type': 'multipart/form-data' } }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteDocumentTemplate({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.delete('/documents/templates/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchDocumentTemplate ({ dispatch }, path) {
    return new Promise(function (resolve, reject) {
      dispatch('API', CONTENT_SERVICE_NAME, { root: true })
        .then(api => {
          api.get('/metadata/' + encodeURI(path))
            .then(response => resolve(response.data))
            .catch(err => reject(err))
        })
    })
  },
  exportSingleInspection ({ dispatch }, { id, template, filename }) {
    const request = {
      template,
      destination: {
        container: 'inspection/' + id + '/files',
        filename: filename || template.filename,
      },
      parameters: {baseUrl: process.env.VUE_APP_API},
      data: { '$schema': 'https://inspectech.se/schema/inspection/inspection-export.json', data: { id } },
    }
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/inspections/' + encodeURIComponent(id) + '/export', request))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  exportInspections ({ dispatch }, { template, query, filename }) {
    const request = {
      template,
      destination: {
        filename: filename || template.filename,
      },
      parameters: {baseUrl: process.env.VUE_APP_API},
      data: { '$schema': 'https://inspectech.se/schema/inspection/structured-search.json', data: { query } },
    }
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/inspections/export', request))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  exportOrders ({ dispatch }, { template, query, filename }) {
    const request = {
      template,
      destination: {
        filename: filename || template.filename,
      },
      parameters: {baseUrl: process.env.VUE_APP_API},
      data: { '$schema': 'https://inspectech.se/schema/order/structured-search.json', data: { query } },
    }
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/order/export', request))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  // - Sequence Numbers
  //
  listSequenceNumbers({ dispatch }) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', SEQUENCE_SERVICE_NAME, { root: true })
        .then(api => api.get('/'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  getSequenceNumberDefinition({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', SEQUENCE_SERVICE_NAME, { root: true })
        .then(api => api.get('/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  createSequenceNumberDefinition({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', SEQUENCE_SERVICE_NAME, { root: true })
        .then(api => api.post('/', data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  updateSequenceNumberDefinition({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', SEQUENCE_SERVICE_NAME, { root: true })
        .then(api => api.post('/' + encodeURIComponent(data.id), data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteSequenceNumberDefinition({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', SEQUENCE_SERVICE_NAME, { root: true })
        .then(api => api.delete('/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  updateSequenceNumber({ dispatch }, { id, prev, next }) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', SEQUENCE_SERVICE_NAME, { root: true })
        .then(api => api.put('/' + encodeURIComponent(id) + '/next', { old: prev, new: next }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  peekSequenceNumber({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', SEQUENCE_SERVICE_NAME, { root: true })
        .then(api => api.get('/' + encodeURIComponent(id) + '/peek'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getEmailTemplates ({ dispatch }, query) {
    let path = '/templates'
    if (query?.tag) {
      path = path + '?tag=' + encodeURIComponent(query.tag)
    }
    return new Promise(function (resolve, reject) {
      return dispatch('API', EMAIL_SERVICE_NAME, { root: true })
        .then(api => api.get(path))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getEmailTemplate ({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', EMAIL_SERVICE_NAME, { root: true })
        .then(api => api.get('/templates/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  saveEmailTemplate ({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', EMAIL_SERVICE_NAME, { root: true })
        .then(api => {
          if(data.id) {
            return api.put('/templates/' + encodeURIComponent(data.id), data)
          } else {
            return api.post('/templates', data)
          }
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  deleteEmailTemplate ({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', EMAIL_SERVICE_NAME, { root: true })
        .then(api => api.delete('/templates/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  getInspectionData ({ dispatch }, { id, baseUrl }) {
    return new Promise(function (resolve, reject) {
        return dispatch('API', VB_SERVICE_NAME, { root: true })
          .then(api => api.get('/admin/inspections/' + encodeURIComponent(id) + '/data?flat=true&baseUrl=' + encodeURI(baseUrl)))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
  },

  getContactData ({ dispatch }, { id, baseUrl }) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/contacts/' + encodeURIComponent(id) + '/data?baseUrl=' + encodeURI(baseUrl)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  parseEmailTemplate ({ dispatch }, { id, data}) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', EMAIL_SERVICE_NAME, { root: true })
        .then(api => api.post('/templates/' + encodeURIComponent(id), data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  sendEmail ({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', EMAIL_SERVICE_NAME, { root: true })
        .then(api => api.post('/messages', data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  analyzeImportFile ({ dispatch }, { group, name, path }) {
    let uri
    if(name) {
      uri = encodeURIComponent(group) + '/' + encodeURIComponent(name) + '/' + encodeURIComponent(path)
    } else {
      uri = encodeURIComponent(group) + '/' + encodeURIComponent(path)
    }
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/import/metadata/' + uri))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  importMetadata ({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/import/metadata', data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  importMetadataGroup ({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/import/metadata/' + encodeURIComponent(data.group), data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  exportMetadata ({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/export/metadata', data, { responseType: 'blob' }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  exportMetadataGroup ({ dispatch }, { group, items }) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/export/metadata/' + encodeURIComponent(group), { items: items }, { responseType: 'blob' }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  analyzeCommonRemarksFile ({ dispatch }, path) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/import/remarks/' + encodeURIComponent(path)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  importCommonRemarks ({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/import/remarks', { items: data }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  exportCommonRemarks ({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/export/remarks', data, { responseType: 'blob' }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  createInsuranceFormRequest({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/dff/' + encodeURIComponent(id), {}))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  // External users
  fetchExternalUsers ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/network/user'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  saveExternalUser ({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.put('/admin/network/user/' + encodeURIComponent(data.id), data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteExternalUser ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.delete('/admin/network/user/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  inviteExternalUser ({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/network/user/invitations', data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchExternalInvitations ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/network/user/invitations'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteExternalInvitation ({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.delete('/admin/network/user/invitations/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchMyInvitations ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/network/user/invitations'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  acceptMyInvitation ({ dispatch }, {id}) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/network/user/invitations/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  denyMyInvitation ({ dispatch }, {id}) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.delete('/network/user/invitations/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchMyNetwork ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/network/user'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  // Organization Network
  fetchOrgNetwork ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/network/org'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  updateOrgRelation ({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.put('/admin/network/org/' + encodeURIComponent(data.id), data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteOrgRelation ({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.delete('/admin/network/org/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  fetchOrgInvitations ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/admin/network/org/invitations'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  sendOrgNetworkInvitation ({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/admin/network/org/invitations', data))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteOrgInvitation ({ dispatch }, id) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.delete('/admin/network/org/invitations/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  fetchMyOrgInvitations ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/network/org/invitations'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  acceptOrgNetworkInvitation ({ dispatch }, {id}) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.post('/network/org/invitations/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  denyOrgNetworkInvitation ({ dispatch }, {id}) {
    return new Promise(function (resolve, reject) {
      return dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.delete('/network/org/invitations/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  // Input Forms
  //
  getInputForms ({ dispatch }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.get('/admin/forms'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  getInputForm ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.get('/admin/forms/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  saveInputForm ({ dispatch }, data) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => {
          if (data.id) {
            return api.put('/admin/forms/' + encodeURIComponent(data.id), data)
          } else {
            return api.post('/admin/forms', data)
          }
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteInputForm ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.delete('/admin/forms/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  getProjectAttributes ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.get('/admin/projects/' + encodeURIComponent(id) + '/attributes'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  getInspectionAttributes ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.get('/admin/inspections/' + encodeURIComponent(id) + '/attributes'))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  exportForm ({ dispatch }, items) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.post('/admin/forms/export', { items: items.map(item => item.id) }, { responseType: 'blob' }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  importForm ({ dispatch }, file) {
    return new Promise(function (resolve, reject) {
      const data = new FormData()
      data.append('file', file, file.name)
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.post('/admin/forms/import', data, { headers: { 'Content-Type': 'multipart/form-data' } }))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  // Inspection Blueprints
  //
  getInspectionBluePrints ({ dispatch }, create = false) {
    return new Promise(function (resolve, reject) {
        dispatch('API', VB_SERVICE_NAME)
          .then(api => api.get('/admin/blueprints?create=' + create))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
  },
  getInspectionBluePrint ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
        dispatch('API', VB_SERVICE_NAME)
          .then(api => api.get('/admin/blueprints/' + encodeURIComponent(id)))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
  },
  saveInspectionBluePrint ({ dispatch }, payload) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => {
          if (payload.id) {
            return api.put('/admin/blueprints/' + encodeURIComponent(payload.id), payload)
          }
          return api.post('/admin/blueprints', payload)
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  deleteInspectionBluePrint ({ dispatch }, { id }) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME)
        .then(api => api.delete('/admin/blueprints/' + encodeURIComponent(id)))
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },

  // Contact Plugin
  //
  isFavorite({ dispatch }, target) {
    return new Promise(function (resolve) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/contact/favorites/' + encodeURIComponent(target)))
        .then(response => resolve(response.data))
        .catch(err => {
          errorHandler('app.error.favorite.get', err)
          resolve(false)
        })
    })
  },
  toggleFavorite({ commit, dispatch }, target) {
    return new Promise(function (resolve, reject) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.put('/contact/favorites/' + encodeURIComponent(target)))
        .then(response => resolve(response.data))
        .catch(err => {
          errorHandler('app.error.favorite.put', err)
          resolve(false)
        })
    })
  },

  fetchFavoriteContacts ({ dispatch }) {
    return new Promise(function (resolve) {
      dispatch('API', VB_SERVICE_NAME, { root: true })
        .then(api => api.get('/contact/favorites'))
        .then(response => resolve(response.data))
        .catch(err => {
          errorHandler('app.error.favorite.contacts', err)
          resolve([])
        })
    })
  },
}

export default actions
