<template>
  <div>
    <v-text-field
      ref="first"
      label="Gatuadress/boxadress"
      :value="value.address"
      :name="prefix + 'Address'"
      :append-icon="appendIcon"
      :rules="rules.address"
      @input="updateAddress('address', $event)"
      @click:append="$emit('click:append', $event)"
    ></v-text-field>

    <v-row dense>
      <v-col cols="5" class="pt-0 pb-0">
        <v-text-field
          label="Postnr"
          :value="value.postalCode"
          :name="prefix + 'PostalCode'"
          :rules="rules.postalCode"
          @input="updateAddress('postalCode', $event)"
        ></v-text-field>
      </v-col>
      <v-col cols="7" class="pt-0 pb-0">
        <v-text-field
          label="Ort"
          :value="value.city"
          :name="prefix + 'City'"
          :rules="rules.city"
          @input="updateAddress('city', $event)"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'CrmAddressFields',
  props: {
    value: {
      type: Object,
      default: () => ({
        address: '',
        postalCode: '',
        city: ''
      })
    },
    prefix: {
      type: String,
      default: ''
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    appendIcon: {
      type: String
    }
  },
  methods: {
    updateAddress (field, input) {
      const payload = { ...this.value }
      payload[field] = input
      this.$emit('input', payload)
    }
  }
}
</script>
