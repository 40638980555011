import Keycloak from 'keycloak-js'
import Inspect from './inspect'

const kcOptions = {
  url: process.env.VUE_APP_API + '/auth',
  realm: process.env.VUE_APP_REALM,
  clientId: process.env.VUE_APP_CLIENT_ID
}

const keycloak = new Keycloak(kcOptions)

const JWT_COOKIE_NAME = 'JWT'

const setCookie = (name, value, days = 1, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path + process.env.VUE_APP_COOKIE_SECURE
}

const deleteCookie = (name) => {
  setCookie(name, '', -1)
}

keycloak.init({ onLoad: 'login-required', promiseType: 'native' }).then(auth => {
  if (!auth) {
    deleteCookie(JWT_COOKIE_NAME)
    window.location.reload()
    return
  }

  setCookie(JWT_COOKIE_NAME, keycloak.token)

  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        setCookie(JWT_COOKIE_NAME, keycloak.token)
      }
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.error('Failed to refresh token', err)
      //keycloak.clearToken()
      //deleteCookie(JWT_COOKIE_NAME)
    })
  }, 3 * 60 * 1000)

  Inspect(keycloak) // Start main application
}).catch(err => {
  // eslint-disable-next-line no-console
  console.error('Initialization failed', err)
  keycloak.clearToken()
  deleteCookie(JWT_COOKIE_NAME)
})
