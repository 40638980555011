export default [
  {
    path: '/:tenant/admin/project-type',
    name: 'project-type-list',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/projectType/ProjectTypeListView'),
    meta: {
      title: 'Projekttyper',
      icon: 'mdi-briefcase-variant',
      tenant: true,
      admin: true,
      project: true
    }
  },
  {
    path: '/:tenant/admin/project-type/:id',
    name: 'project-type',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/projectType/ProjectTypeAdmin'),
    meta: {
      title: 'Projekttyp',
      icon: 'mdi-briefcase-variant',
      tenant: true
    }
  },
  {
    path: '/:tenant/projects/:id',
    name: 'show-project',
    props: true,
    component: () => import(/* webpackChunkName: "inspection" */ '../views/ShowProjectView'),
    meta: {
      tenant: true,
      icon: 'mdi-briefcase-variant'
    }
  },
  {
    path: '/:tenant/projects/:id/:inspection',
    name: 'show-inspection',
    props: true,
    component: () => import(/* webpackChunkName: "inspection" */ '../views/ShowProjectView'),
    meta: {
      tenant: true,
      icon: 'mdi-briefcase-variant'
    }
  },
]
