import basicRoutes from './basic'
import projectRoutes from './project'
import inspectRoutes from './inspect'
import adminRoutes from './admin'
import contactRoutes from './contact'

export default [
  ...basicRoutes,
  ...projectRoutes,
  ...inspectRoutes,
  ...adminRoutes,
  ...contactRoutes,
]

const CREATE_TENANT = 'init'
const SELECT_TENANT = 'tenant' // 'tenant-select'

const nonTenantRoutes = [CREATE_TENANT, SELECT_TENANT, 'fortnox-activation-entrypoint', 'visma-activation-entrypoint']

const unauthorized = function(to, store) {
  return to.matched.some(record => record.meta.admin) && !store.getters['account/isAdmin']
}

export function beforeEach (store) {
  return (to, from, next) => {

    if (nonTenantRoutes.includes(to.name)) {
      // Tenant is not required for this route.
      next()
    } else {
      // Tenant is required for this route.
      const tenantId = to.params.tenant || store.getters['account/tenantId']
      if (tenantId) {
        store.dispatch('account/selectTenant', { id: tenantId })
          .then(tenant => {
            if (tenant) {
              if(to.name === 'home') {
                next({ name: 'dashboard', params: { ...to.params, tenant: tenant.id } })
              } else if (unauthorized(to, store)) {
                next({ name: 'home' })
              /*
              } else  if (to.name === 'show-project') {
                const id = to.params.id
                store.dispatch('fetchProjectDetails', { id })
                  .then(details => {
                    const estate = details.estates[0]
                    if (estate?.inspections && estate.inspections[0]) {
                      const inspection = estate.inspections[0].inspection.id
                      next({ name: 'show-inspection', params: { tenant: tenant.id, id, inspection } })
                    } else {
                      next()
                    }
                  })
               */
              } else {
                next()
              }
            } else {
              console.warn('Invalid tenant: ' + to.params.tenant)
              // If we end up here, the tenant was not a valid tenant for this user.
              next({ name: SELECT_TENANT })
            }
          })
          .catch(err => {
            console.error(err)
            //errorHandler('error.router', err)
            next(false)
          })
      } else {
        // Tenant is not known, try to figure out what tenant to use.
        const tenants = store.getters['account/tenants']
        if(tenants.length === 0) {
          store.dispatch('initApplication', '0', { root: true })
            .then(data => {
              const tenant = data.tenant
              if(tenant) {
                next({ name: 'dashboard', params: { tenant: tenant.id } })
              } else {
                next({ name: SELECT_TENANT })
              }
            })
        } else if(tenants.length === 1) {
          console.log('tenant was not known, but user only has one tenant, so select it: ' + tenants[0].id)
          // If tenant is not set (after login) and we only have one tenant, select it.
          store.dispatch('account/selectTenant', { id: tenants[0].id })
            .then(tenant => {
              if (unauthorized(to, store)) {
                next({ name: 'home' })
              } else {
                next({ name: to.name, params: { ...to.params, tenant: tenant.id } })
              }
            })
        } else {
          console.log('user have access to multiple tenants to present options: ' + tenants)
          // Multiple tenants, go to tenant management view.
          next({ name: SELECT_TENANT })
        }
      }
    }
  }
}
