// - Sequence Numbers
//
const SEQUENCE_SERVICE_NAME = 'sequences'

export default {
  namespaced: true,
  actions: {
    listSequenceNumbers({ dispatch }) {
      return new Promise(function (resolve, reject) {
        return dispatch('API', SEQUENCE_SERVICE_NAME, { root: true })
          .then(api => api.get('/'))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },
    getSequenceNumberDefinition({ dispatch }, id) {
      return new Promise(function (resolve, reject) {
        return dispatch('API', SEQUENCE_SERVICE_NAME, { root: true })
          .then(api => api.get('/' + encodeURIComponent(id)))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },
    createSequenceNumberDefinition({ dispatch }, data) {
      return new Promise(function (resolve, reject) {
        return dispatch('API', SEQUENCE_SERVICE_NAME, { root: true })
          .then(api => api.post('/', data))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },
    updateSequenceNumberDefinition({ dispatch }, data) {
      return new Promise(function (resolve, reject) {
        return dispatch('API', SEQUENCE_SERVICE_NAME, { root: true })
          .then(api => api.post('/' + encodeURIComponent(data.id), data))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },
    deleteSequenceNumberDefinition({ dispatch }, id) {
      return new Promise(function (resolve, reject) {
        return dispatch('API', SEQUENCE_SERVICE_NAME, { root: true })
          .then(api => api.delete('/' + encodeURIComponent(id)))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },
    updateSequenceNumber({ dispatch }, { id, prev, next }) {
      return new Promise(function (resolve, reject) {
        return dispatch('API', SEQUENCE_SERVICE_NAME, { root: true })
          .then(api => api.put('/' + encodeURIComponent(id) + '/next', { old: prev, new: next }))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    },
    peekSequenceNumber({ dispatch }, id) {
      return new Promise(function (resolve, reject) {
        return dispatch('API', SEQUENCE_SERVICE_NAME, { root: true })
          .then(api => api.get('/' + encodeURIComponent(id) + '/peek'))
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      })
    }
  }
}
