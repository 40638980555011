<template>
  <v-footer app>
    <span class="caption">&copy; 2024 InspecTech AB</span>
    <a v-for="link in links" :key="link.href" :href="link.href" v-text="link.text" target="_blank" rel="noopener" class="ml-5 tiny caption grey--text text-decoration-none"></a>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data () {
    return {
      links: [
        { href: 'https://www.inspectech.se/docs/user-agreement', text: 'Användarvillkor' },
        { href: 'https://www.inspectech.se/docs/privacy-policy', text: 'Personuppgiftspolicy' }
      ]
    }
  },
}
</script>

<style scoped>
  .tiny {
    font-size: 9px !important;
  }
</style>
