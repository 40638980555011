const Home =  () => import(/* webpackChunkName: "home" */ '../views/Home')

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'app.home.title',
      icon: 'mdi-home',
      menu: true
    }
  },
  {
    path: '/welcome',
    name: 'tenant',
    component: () => import(/* webpackChunkName: "admin" */ '../views/SelectTenantView'),
    meta: {
      title: 'Organisationer',
      icon: 'mdi-bank'
    }
  },
  {
    path: '/:tenant',
    name: 'dashboard',
    component: Home,
    meta: {
      title: 'Hem',
      icon: 'mdi-home',
      tenant: true
    }
  },
  {
    path: '/:tenant/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/UserProfile'),
    meta: {
      title:'Användarprofil',
      icon: '$person'
    }
  },
]
