/* eslint-disable no-console */
import Vue from 'vue'
import Vuex from 'vuex'
import Router from 'vue-router'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import Error from './Error.vue'
import appRoutes from './router'
import { beforeEach } from './router'
import axios from 'axios'
import EventBus from './eventbus'
import moment from 'moment'
import {marked} from 'marked'

import CrmPlugin from './plugins/crm'
import CrmCommonPlugin from 'vue-gr8crm-common'
import CrmBillingPlugin from 'vue-gr8crm-billing'
import CrmSettingsPlugin from 'vue-gr8crm-settings'
import CrmMetadataPlugin from 'vue-gr8crm-metadata'
import CrmContactPlugin from 'vue-gr8crm-contact'
import CrmContentPlugin from 'vue-gr8crm-content'
import CrmActivityPlugin from 'vue-gr8crm-activity'
import Notifications from 'vue-gr8crm-notification'

import rootStore from './store'
import accountStore from './store/account'
import sequenceStore from './store/sequence'
import emailStore from './store/email'
import fortnoxStore from './store/fortnox'
import vismaStore from './store/visma'

import '@/components/base'

import 'vue-gr8crm-metadata/dist/vue-gr8crm-metadata.css'
import 'vue-gr8crm-content/dist/vue-gr8crm-content.css'
import 'vue-gr8crm-contact/dist/vue-gr8crm-contact.css'

Vue.config.productionTip = false

moment.locale('sv')

const renderer = new marked.Renderer()
renderer.link = function(href, title, text) {
  var link = marked.Renderer.prototype.link.apply(this, arguments)
  return link.replace("<a","<a target='_blank'")
}

marked.setOptions({ renderer: renderer })

Vue.use(Router)
Vue.use(Vuex)

const errorHandler = (message, error) => EventBus.$emit('error', { message, error })

axios.defaults.baseURL = process.env.VUE_APP_API
axios.defaults.withCredentials = true
axios.defaults.timeout = 300000 // 5 minutes

axios.interceptors.request.use(config => {
  config.headers['X-CRM-Client'] = process.env.VUE_APP_CLIENT
  config.headers['Cache-Control'] = 'no-cache'
  config.maxContentLength = 1024 * 1024 * 32
  config.maxBodyLength = 1024 * 1024 * 30
  return config
})
axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response) {
    const response = error.response
    const headers = response.headers || {}
    if (response.status === 401) {
      errorHandler('error.unauthorized', error)
      window.location.reload()
    } else if (response.status === 403) {
      errorHandler('error.forbidden', 'Du saknar behörighet att utföra operationen')
    } else if (headers['content-type'] === 'application/problem+json') {
      const problem = response.data || { title: 'Error', detail: error }
      const title = problem.title || 'Error'
      const message = problem.detail || error
      errorHandler(title, message)
    }
  } else if(error.request) {
    errorHandler('error.request', error)
  } else {
    errorHandler('error.default', error)
  }
  return Promise.reject(error)
})

Vue.prototype.$http = axios
Vue.prototype.$eventBus = EventBus

Vue.filter('formatTimestamp', function (value) {
  if (value) {
    return moment(value).format('YYYY-MM-DD HH:mm')
  }
})
Vue.filter('formatDateTimeLong', function (value) {
  if (value) {
    return moment(value).locale('sv').format('dddd D MMMM [kl.] H:mm')
  }
})
const moneyFormatter = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK'
})
Vue.filter('formatMoney', function (value) {
  return moneyFormatter.format(value || 0)
})
Vue.filter('formatBytes', function (a) {
  if (a === 0) return '0'
  var c = 1024
  var e = ['', 'KB', 'MB', 'GB', 'TB']
  var f = Math.floor(Math.log(a) / Math.log(c))
  return parseFloat((a / Math.pow(c, f)).toFixed(0)) + ' ' + e[f]
})

const store = new Vuex.Store({
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  ...rootStore
})

store.registerModule('account', accountStore)
store.registerModule('sequence', sequenceStore)
store.registerModule('email', emailStore)
store.registerModule('fortnox', fortnoxStore)
store.registerModule('visma', vismaStore)

// Workaround because Vue Router does not have a getAllRoutes() method.
const routeCollector = {
  _routes: [],
  addRoutes: function (array) {
    for(let i = 0; i < array.length; i++) {
      const item = array[i]
      if (!this._routes.find(entry => entry.name === item.name)) {
        this._routes.push(item)
      }
    }
  },
  getAllRoutes: function() {
    return this._routes
  }
}

routeCollector.addRoutes(appRoutes)

const uiPlugins = [
  {
    component: () => import(/* webpackChunkName: "plugin" */ '@/components/contact/InspectionListContactPlugin.vue'),
    location: 'contact-show'
  },
]
Vue.use(CrmCommonPlugin, { store, router: routeCollector, plugins: uiPlugins })
Vue.use(CrmBillingPlugin, { store, router: routeCollector })
Vue.use(CrmPlugin, { store })
Vue.use(CrmSettingsPlugin, { store, router: routeCollector })
Vue.use(CrmMetadataPlugin, { store, router: routeCollector })
Vue.use(CrmContactPlugin, { store, router: routeCollector })
Vue.use(CrmContentPlugin, { store, router: routeCollector })
Vue.use(CrmActivityPlugin, { store, router: routeCollector })

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE,
  scrollBehavior: (to, from, savedPosition) => {
    return savedPosition || { top: 0 }
  },
  routes: routeCollector.getAllRoutes()
})

router.beforeEach(beforeEach(store))

export default async function (keycloak) {
  Vue.prototype.$keycloak = keycloak
  EventBus.$on('application:init', (data) => {
    EventBus.$disconnect()
    const tenant = data.tenant
    const sub = data.sub
    const session = data.session
    if (tenant && sub && session) {
      EventBus.$connect(process.env.VUE_APP_WS + '/notifications/' + encodeURIComponent(tenant.id) + '/' + encodeURIComponent(sub)  + '/' + encodeURIComponent(session))
    }

    store.dispatch('fetchNotifications')
      .catch(console.error)
  })

  EventBus.$on('logout', () => {
    const appUrl = process.env.VUE_APP_API + process.env.VUE_APP_BASE
    localStorage.removeItem('tenant')
    keycloak.logout({ redirectUri: appUrl })
  })

  EventBus.$on('change-password', () => {
    const appUrl = process.env.VUE_APP_API + process.env.VUE_APP_BASE
    keycloak.accountManagement({ redirectUri: appUrl })
  })

  let error = null
  try {
    await keycloak.loadUserInfo()
      .then(userInfo => {
        store.commit('account/setUser', userInfo)
        Vue.use(Notifications, {
          store,
          url: process.env.VUE_APP_WS + '/notifications/' + encodeURIComponent(userInfo.sub),
          connectManually: true
        })
      })
  } catch (err) {
    console.error(err)
    error = err
  }

  if (error) {
    return new Vue({
      router,
      store,
      vuetify,
      render: h => h(Error, { props: { error }})
    }).$mount('#app')
  }

  return new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}
