/**
 * Usage:
 * import { loadGoogleMaps } from 'googe-maps-loader'
 *
 * loadGoogleMaps({key: 'YOUR-API-KEY'})
 *  .then(google => {
 *    const map = new google.maps.Map ...
 *  })
 */

const mapsScriptUrl = 'https://maps.googleapis.com/maps/api/js'

let mapsLoaderPromise = null

export function getMapsLoader (apiKey) {
  // If already included in the page:
  if (window.google && window.google.maps) {
    return Promise.resolve(window.google.maps)
  }
  if (!mapsLoaderPromise) {
    mapsLoaderPromise = new Promise(resolve => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.onload = () => resolve(window.google.maps)
      script.src = mapsScriptUrl + '?key=' + apiKey
      document.body.appendChild(script)
    })
  }
  return mapsLoaderPromise
}

export default function loadGoogleMaps (apiKey) {
  return getMapsLoader(apiKey)
}
