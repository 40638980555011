export const state = () => ({
  tenant: null,
  logo: null,
  user: null,
  userSettings: {},
  avatar: null,
  account: null,
  accounts: [],
  roles: [],
  permissions: [],
})
